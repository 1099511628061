import { isSetting, isCardOrdering, isGameRules } from "helper/functions";
import React, { useState } from "react";
import { AuthState, Options } from "types";
import CardOrderOptionList from "./CardOrderOptionList";
import GameRulesList from "./GameRulesList";
import OptionButton from "./OptionButton";

interface Props {
  options: Options;
  setOptions: React.Dispatch<React.SetStateAction<Options>>;
  authState: AuthState;
}

const GameOptions: React.FC<Props> = ({ options, setOptions, authState }) => {
  const [showGameOptions, setShowGameOptions] = useState(false);

  return (
    <div className="flex">
      <div className="text-xs text-center mx-auto">
        <div
          className="text-sm cursor-pointer my-2 underline"
          onClick={() => setShowGameOptions(!showGameOptions)}
        >
          Game Options
        </div>
        {showGameOptions && (
          <div className="text-sm text-center flex flex-col bg-white p-2 rounded shadow">
            {(Object.keys(options) as Array<keyof typeof options>).map(
              (option) => {
                const setting = options[option];

                return (
                  <div key={option}>
                    {isSetting(setting) && (
                      <OptionButton
                        options={options}
                        optionType={option}
                        optionInfo={setting}
                        setOptions={setOptions}
                      />
                    )}

                    {isCardOrdering(setting) && (
                      <CardOrderOptionList
                        cardOrdering={setting}
                        options={options}
                        setOptions={setOptions}
                      />
                    )}

                    {isGameRules(setting) && (
                      <GameRulesList
                        gameRule={setting}
                        options={options}
                        setOptions={setOptions}
                        authState={authState}
                      />
                    )}
                  </div>
                );
              }
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default GameOptions;
