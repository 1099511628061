import React, { useEffect, useState } from "react";
import PlayerList from "./PlayerInfo/PlayerList";
import LobbyInterface from "./LobbyInterface";
import Header from "../../components/Header";
import { AuthState, GameState, Options } from "types";
import GameMenu from "./PlayerInterface/GameMenu";
import Spectator from "./SpectatorView/Spectator";
import { gameEnded } from "helper/functions";
import Chatroom from "./Chat/Chatroom";
import CurrentGameOptions from "./CurrentGameOptions";
import VideoAd from "components/Ads/VideoAd";

interface Props {
  clientId: string;
  gameState: GameState;
  currentGameId: string | null;
  authState: AuthState;
  options: Options;
  setOptions: React.Dispatch<React.SetStateAction<Options>>;
}

const Game: React.FC<Props> = ({
  clientId,
  gameState,
  authState,
  options,
  setOptions,
}) => {
  const [users, setUsers] = useState<any>({});
  const [showAd, setShowAd] = useState(false);

  //assign user to clientId
  useEffect(() => {
    const usernames = gameState.clientIds.reduce((unames, cId) => {
      if (!gameState.players[cId].username) return unames;
      if (unames === "") {
        return gameState.players[cId].username || "";
      }
      return unames + "," + gameState.players[cId].username;
    }, "");

    async function getLobbyUsers() {
      const usersInfo = await fetch(
        `${process.env.REACT_APP_API_URL}/user/lobby/${usernames}`
      )
        .then((response) => response.json())
        .then((json) => json)
        .catch((e) => {
          console.error(e);
        });

      const newUsers = gameState.clientIds.reduce((assigned, cId) => {
        const currentUser = usersInfo?.find(
          (u: any) => u.username === gameState.players[cId].username
        );
        return { ...assigned, [cId]: currentUser };
      }, {});

      setUsers(newUsers);
    }
    getLobbyUsers();
  }, [gameState.clientIds.length]);

  useEffect(() => {
    if (gameState.startGame && gameEnded(gameState))
      setShowAd(true);
    else if (!gameState.startGame) 
      setShowAd(false)
  }, [gameEnded(gameState)]);

  return (
    <>
      <GameMenu
        clientId={clientId}
        gameState={gameState}
        authState={authState}
        options={options}
        setOptions={setOptions}
      />
      <Chatroom
        clientId={clientId}
        authState={authState}
        gameState={gameState}
      />
      {showAd && (
        <VideoAd gameState={gameState} setShowAd={setShowAd} />
      )}
      {gameState.startGame ? (
        gameState.clientIds.includes(clientId) ? (
          <PlayerList
            users={users}
            clientId={clientId}
            gameState={gameState}
            showCards={gameState.options.showCards?.state}
          />
        ) : (
          <Spectator gameState={gameState} users={users} />
        )
      ) : (
        <>
          <Header title={`Lobby - ${gameState.gameName}`} />
          <div className="md:flex md:space-x-2 max-w-5xl mx-auto">
            <LobbyInterface
              clientId={clientId}
              gameState={gameState}
              users={users}
            />
            <CurrentGameOptions
              options={
                gameState.host === clientId ? options : gameState.options // prevents non-host from changing options
              }
              setOptions={setOptions}
              clientId={clientId}
              authState={authState}
              gameState={gameState}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Game;
