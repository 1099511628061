import React, { useEffect, useRef, useState } from "react";

const names = [
  "Big Two",
  "大老二",
  "锄大地",
  "步步高升",
  "Pusoy Dos",
  "Sjalaliën",
  "Deuces",
];

const TitleCarousel: React.FC = () => {
  const [index, setIndex] = useState(0);
  const elemHeight = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setIndex((index + 1) % names.length);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [index]);

  return (
    <div className="justify-center grid grid-cols-3 mx-auto mt-4 w-96">
      <div className="text-center font-semibold text-xl flex ml-auto">
        <div className="-mr-px -mt-1">2</div>
        <div className="mt-1">♠</div>
      </div>
      <div className="overflow-y-hidden h-8">
        {names.map((n, i) => (
          <div
            key={n}
            ref={elemHeight}
            className={`transition-all text-center font-semibold text-xl duration-200`}
            style={{transform: `translate(0, -${(elemHeight.current?.offsetHeight || 0) * index}px)`}}
          >
            {n}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TitleCarousel;
