import React, { useState, useRef } from "react";
import { cardInfo } from "../../App";
import Card from "components/Card";

interface Props {
  hand: string[];
  cardsToPlay: string[];
  setHand: React.Dispatch<React.SetStateAction<string[]>>;
  handleCardsToPlay: (card: string) => void;
}

const cardSpacing = (n: number) =>
  n > 5 ? (0.5 - (6.5 * (n - 5)) / n) / 2 : 0.25;

const Hand: React.FC<Props> = ({
  hand,
  setHand,
  cardsToPlay,
  handleCardsToPlay,
}) => {
  const [dragging, setDragging] = useState(false);
  const dragItem = useRef<string | null>(null);
  const dragItemNode = useRef<EventTarget | null>(null);

  const n = hand.filter((card) => !cardsToPlay.includes(card)).length;
  const m = (i: number) =>
    dragging && dragItem.current && i === hand.indexOf(dragItem.current);

  function handleDragStart(e: React.DragEvent<HTMLDivElement>, card: string) {
    dragItemNode.current = e.target;
    dragItemNode.current?.addEventListener("dragend", handleDragEnd);
    dragItem.current = card;
    setTimeout(() => {
      setDragging(true);
    }, 0);
  }

  function handleDragEnter(
    e: React.DragEvent<HTMLDivElement>,
    targetCard: string
  ) {
    const cardIndex = hand.indexOf(targetCard);

    if (dragItemNode.current !== e.target && dragItem.current) {
      const currentCardIndex = hand.indexOf(dragItem.current);
      const newHand = [...hand];
      newHand.splice(cardIndex, 0, newHand.splice(currentCardIndex, 1)[0]);
      setHand(newHand);
    }
  }

  function handleDragEnd(e: Event) {
    setDragging(false);
    dragItem.current = null;
    dragItemNode.current?.removeEventListener("dragend", handleDragEnd);
    dragItemNode.current = null;
  }

  return (
    <div className="bg-red-700 border-4 border-red-900 flex mx-2 overflow-x-auto py-2 relative rounded-lg shadow-2xl sm:justify-center sm:overflow-x-visible">
      {hand
        .filter((card) => !cardsToPlay.includes(card))
        .map((card, i) => (
          <div
            style={{
              marginRight: `${
                (i === n - 1 ? 0 : cardSpacing(n)) + (m(i) ? 0.25 : 0)
              }rem`,
              marginLeft: `${
                (i === 0 ? 0 : cardSpacing(n)) + (m(i) ? 0.25 : 0)
              }rem`,
            }}
            className={`transform `}
            onDragStart={(e) => handleDragStart(e, card)}
            onDragEnter={dragging ? (e) => handleDragEnter(e, card) : undefined}
            onClick={() => handleCardsToPlay(card)}
            key={`${cardInfo[card].rank}${cardInfo[card].suit}`}
            draggable
          >
            {m(i) ? (
              <div
                className="shadow-lg bg-gray-400 opacity-75 rounded"
                style={{ width: "6rem", height: "8.4rem" }}
              ></div>
            ) : (
              <div>
                <div
                  className={`transform cursor-pointer transition-all ease-out duration-75 ${
                    dragging ? "" : "hover:-translate-y-6"
                  } sm:hover:mx-1`}
                >
                  <Card
                    dragging={dragging}
                    rank={cardInfo[card].rank}
                    suit={cardInfo[card].suit}
                  />
                </div>
                <div className="absolute w-full h-6 bottom-0 inset-x-0"></div>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default Hand;
