import { cardInfo } from "App";
import { useState } from "react";
import { AuthState, CardOrdering, GameRules, GameState, Setting } from "types";
import { ranks, suits } from "./constants";

export const ascRank = (a: string, b: string) =>
  ranks.indexOf(cardInfo[a].rank) - ranks.indexOf(cardInfo[b].rank);
export const ascSuit = (a: string, b: string) =>
  suits.indexOf(cardInfo[a].suit) - suits.indexOf(cardInfo[b].suit);

export const gameEnded = (gameState: GameState) =>
  gameState.clientIds.filter((cId) => gameState.players[cId].cards.length === 0)
    .length !== 0;

export const isAuthenticated = async (token: string) => {
  let auth: boolean = false;

  const result = await fetch(`${process.env.REACT_APP_API_URL}/auth/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((json) => {
      auth = true;
      console.log(json);
      return json;
    })
    .catch((e) => {
      console.log(e);
    });

  if (!result) return { isAuthenticated: false, isLoading: false };

  return { isAuthenticated: auth, payload: result.payload, isLoading: false };
};

export function useAuth() {
  const [authState, setAuthState] = useState<AuthState>({
    isAuthenticated: false,
    isLoading: true,
  });

  function refreshAuthState() {
    const token = localStorage.getItem("jwt");
    if (token !== null) {
      const getAuthState = async () => {
        const authState = await isAuthenticated(token);
        setAuthState(authState);
      };
      getAuthState();
    } else {
      setAuthState({ ...authState, isLoading: false });
    }
  }

  return { authState, setAuthState, refreshAuthState };
}

export const playerHasFreebie = (gameState: GameState): boolean => {
  const { clientIds } = gameState;

  return (
    clientIds.filter(
      (cId) => playerHasPassed(gameState, cId) || playerHasWon(gameState, cId)
    ).length === clientIds.length
  );
};
function playerHasPassed(gameState: GameState, cId: string): boolean {
  return gameState.players[cId].pass;
}
function playerHasWon(gameState: GameState, cId: string): boolean {
  return gameState.players[cId].cards.length === 0;
}

//typeguard for setting
export function isSetting(
  option: CardOrdering | Setting | GameRules
): option is Setting {
  return (option as Setting).state !== undefined;
}

//typeguard for cardordering
export function isCardOrdering(
  option: CardOrdering | Setting | GameRules
): option is CardOrdering {
  const { fiveCardHandOrder, suitOrder, rankOrder } = option as CardOrdering;
  return (
    fiveCardHandOrder !== undefined &&
    suitOrder !== undefined &&
    rankOrder !== undefined
  );
}

export function isGameRules(
  option: CardOrdering | Setting | GameRules
): option is GameRules {
  const {
    flushSuitPriority,
    lowestCardGoesFirst,
    mustPlayLowestCard,
    reverseOrderNextGame,
    disableSuits,
    straightVariant,
  } = option as GameRules;
  return (
    flushSuitPriority !== undefined &&
    lowestCardGoesFirst !== undefined &&
    mustPlayLowestCard !== undefined &&
    reverseOrderNextGame !== undefined &&
    disableSuits !== undefined &&
    straightVariant !== undefined
  );
}

export function getAllowedGameRules(
  purchasedGameRules?: {
    description: string;
    gameRule: string;
    renewDate: number;
  }[]
) {
  if (!purchasedGameRules) {
    return [];
  }
  const allowedGameRules = purchasedGameRules.reduce(
    (
      allowed: string[],
      purchase: {
        description: string;
        gameRule: string;
        renewDate: number;
      }
    ) => {
      if (allowed.includes(purchase.gameRule)) {
        if (purchase.gameRule === "straightVariant") {
          if (!allowed.includes(purchase.description)) {
            allowed.push(purchase.description);
            return allowed;
          }
        }
        return allowed;
      } else {
        allowed.push(purchase.gameRule);
        return allowed;
      }
    },
    []
  );
  return allowedGameRules;
}
