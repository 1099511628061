import React from "react";
import { Helmet } from "react-helmet-async";

interface Props {
  title?: string;
}

const Header: React.FC<Props> = ({ title }) => {
  if (!title) {
    return (
      <Helmet>
        <title>Big Two (锄大地, 大老二)</title>
      </Helmet>
    );
  }

  return (
    <Helmet>
      <title>{title} | Big Two (锄大地, 大老二)</title>
    </Helmet>
  );
};

export default Header;
