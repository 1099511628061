import { isAuthenticated } from "helper/functions";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory } from "react-router-dom";
import { AuthState } from "types";

const ResetPasswordRequest = ({ authState }: { authState: AuthState }) => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm();
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (authState.isAuthenticated) {
      history.push("/game");
    }
  }, [authState.isAuthenticated]);

  const onSubmit = async (data: any) => {
    const token = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/saveresethash`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: data.email,
        }),
      }
    )
      .then((response) => response.json())
      .then(() => setShowConfirmation(true))
      .catch((e) => console.error(e));
  };

  return (
    <div className="max-w-5xl mx-auto px-2">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Reset your password
        </h2>
      </div>
      {showConfirmation ? (
        <p className="text-center my-5">
          You've been sent a password reset link to your email. Check your email
          and click the link to reset your password!
        </p>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <div className="mt-1">
              <input
                {...register("email", { required: true })}
                id="email"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className={`bg-indigo-600 hover:bg-indigo-700 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
              Reset Password
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ResetPasswordRequest;
