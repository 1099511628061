import { cardInfo } from "App";
import React from "react";
import Card from "./Card";

const translate = [
  "translate-y-2",
  "translate-y-0.5",
  "",
  "translate-y-0.5",
  "translate-y-2",
];
const rotate = ["-rotate-12", "-rotate-6", "", "rotate-6", "rotate-12"];

interface Props {
  cards: string[];
}
const Hand: React.FC<Props> = ({ cards }) => {
  let hand;
  if (!cards) return null
  if (cards.length === 1) {
    hand = (
      <Card
        xsmall
        suit={cardInfo[cards[0]].suit}
        rank={cardInfo[cards[0]].rank}
      />
    );
  }

  if (cards.length === 2) {
    hand = cards.map((c, i) => (
      <div
        key={i}
        className={`transform ${translate[i * 2 + 1]} ${rotate[i * 2 + 1]}`}
      >
        <Card xsmall suit={cardInfo[c].suit} rank={cardInfo[c].rank} />
      </div>
    ));
  }

  if (cards.length === 3) {
    hand = cards.map((c, i) => (
      <div key={i} className={`transform ${translate[i + 1]} ${rotate[i + 1]}`}>
        <Card xsmall suit={cardInfo[c].suit} rank={cardInfo[c].rank} />
      </div>
    ));
  }

  if (cards.length === 5) {
    hand = cards.map((c, i) => (
      <div key={i} className={`transform ${translate[i]} ${rotate[i]}`}>
        <Card xsmall suit={cardInfo[c].suit} rank={cardInfo[c].rank} />
      </div>
    ));
  }

  return <div className="flex -space-x-3 transform">{hand}</div>;
};

export default Hand;
