import React, { useEffect, useRef, useState } from "react";
import GameOptions from "./GameOptions";
import RejoinGame from "./RejoinGame";
import { handleCreateGame } from "api";
import { useHistory } from "react-router-dom";
import Button from "components/Button";
import Header from "components/Header";
import TitleCarousel from "components/TitleCarousel";
import Browser from "features/Browser/Browser";
import { AuthState, GameState, Options } from "types";

interface Props {
  clientId: string;
  gameState: GameState | null;
  currentGameId: string | null;
  authState: AuthState;
  options: Options;
  setOptions: React.Dispatch<React.SetStateAction<Options>>;
}

const CreateGame: React.FC<Props> = ({
  clientId,
  gameState,
  currentGameId,
  authState,
  options,
  setOptions,
}) => {
  const history = useHistory();
  const [name, setName] = useState("");
  const [gameName, setGameName] = useState("");
  const currentGId = useRef(gameState?.id);

  useEffect(() => {
    if (authState.payload) {
      setName(authState.payload?.username);
    }
  }, [authState.isAuthenticated]);

  useEffect(() => {
    if (currentGId.current !== gameState?.id) {
      if (!gameState) {
        currentGId.current = undefined;
        history.push(`/game`);
      } else {
        history.push(`/game/${gameState?.id}`);
      }
    }
  }, [gameState]);

  return (
    <>
      <Header />
      <TitleCarousel />
      <div className="flex justify-center mt-2">
        <div className="flex flex-col space-y-4">
          <div className="">
            <input
              type="text"
              placeholder="Player Name"
              className="px-4 py-3 text-lg border flex-shrink focus:outline-none focus:ring focus:border-blue-300"
              value={name}
              disabled={authState.isAuthenticated}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className=" flex flex-col">
            <input
              type="text"
              placeholder="Game Name"
              className="px-4 py-3 text-lg border flex-shrink focus:outline-none focus:ring focus:border-blue-300"
              value={gameName}
              onChange={(e) => setGameName(e.target.value)}
            />
            <Button
              onClick={() =>
                handleCreateGame(
                  clientId,
                  gameName,
                  name,
                  options,
                  false,
                  authState.payload?.username
                )
              }
            >
              New game
            </Button>
            {authState.isAuthenticated && (
              <Button
                onClick={() =>
                  handleCreateGame(
                    clientId,
                    gameName,
                    name,
                    options,
                    true,
                    authState.payload?.username
                  )
                }
              >
                Join Matchmaking
              </Button>
            )}
          </div>
        </div>
      </div>
      <GameOptions
        options={options}
        setOptions={setOptions}
        authState={authState}
      />
      <RejoinGame
        gameState={gameState}
        clientId={clientId}
        currentGameId={currentGameId}
        name={name}
      />
      <Browser clientId={clientId} name={name} authState={authState} />
    </>
  );
};

export default CreateGame;
