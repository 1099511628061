import { useEffect } from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";
import { AuthState } from "types";

type Props = {
  authState: AuthState;
};

const ProtectedRoute: React.FC<RouteProps & Props> = ({
  children,
  authState,
  ...rest
}) => {
  const history = useHistory()
  useEffect(() => {
    if (!authState.isAuthenticated && !authState.isLoading) {
      history.push("/signin");
    }
  }, [authState.isAuthenticated, authState.isLoading]);

  return (
    <Route
      {...rest}
      // render={() => children}
    />
  );
};

export default ProtectedRoute;
