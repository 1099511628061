import React, { useRef, useState } from "react";
import { AuthState, GameRules, Options } from "types";
import { ruleDescriptions } from "helper/constants";
import { getAllowedGameRules } from "helper/functions";
import { Link } from "react-router-dom";

type Props = {
  gameRule: GameRules;
  options: Options;
  authState: AuthState;
  setOptions: React.Dispatch<React.SetStateAction<Options>>;
  setOptionsUpdated?: React.Dispatch<React.SetStateAction<boolean>>;
};

const freeEvent = true;
const freeEventText = "Expires Mar 1, 2022";

const GameRulesList: React.FC<Props> = ({
  gameRule,
  options,
  authState,
  setOptions,
  setOptionsUpdated,
}) => {
  const allowedGameRules = getAllowedGameRules(
    authState.payload?.purchasedGameRules
  );

  return (
    <div>
      {/* <pre>{JSON.stringify(gameRule, null,2)}</pre> */}
      {(Object.keys(gameRule) as Array<keyof typeof gameRule>)
        .filter((rule) => {
          const ruleDesc = ruleDescriptions.find(
            (ruleDesc) => ruleDesc.rule === rule
          );

          return !ruleDesc?.todo && rule !== "straightVariant";
        })
        .map((rule) => {
          const ruleDesc = ruleDescriptions.find(
            (ruleDesc) => ruleDesc.rule === rule
          );
          if (allowedGameRules.includes(rule) || freeEvent) {
            return (
              <div
                className="flex justify-center items-center space-x-4 border-b w-full"
                key={rule}
              >
                <p className="flex-grow text-left">{ruleDesc?.text}</p>
                <Link to="/perks">
                  <button className={`px-1 border-2 rounded mt-1`}>
                    {freeEvent && freeEventText}
                  </button>
                </Link>
                <button
                  onClick={() => {
                    setOptions({
                      ...options,
                      gameRules: { ...gameRule, [rule]: !gameRule[rule] },
                    });

                    setOptionsUpdated && setOptionsUpdated(true);
                  }}
                  className={`px-1 border-2 rounded mt-1 ${
                    gameRule[rule]
                      ? "bg-green-500 border-green-600 text-green-100"
                      : "bg-gray-500 text-gray-100 border-gray-600"
                  }`}
                >
                  {`${gameRule[rule] ? "On" : "Off"}`}
                </button>
              </div>
            );
          } else {
            return (
              <div
                className="flex justify-center items-center space-x-4 border-b w-full"
                key={rule}
              >
                <p className="flex-grow text-left">{ruleDesc?.text}</p>
                <Link to="/perks">
                  <button className={`px-1 border-2 rounded mt-1`}>
                    Purchase perk
                  </button>
                </Link>
                <button
                  className={`px-1 border-2 rounded mt-1 ${
                    gameRule[rule]
                      ? "bg-green-500 border-green-600 text-green-100"
                      : "bg-gray-500 text-gray-100 border-gray-600"
                  }`}
                >
                  {`${gameRule[rule] ? "On" : "Off"}`}
                </button>
              </div>
            );
          }
        })}
      {(Object.keys(gameRule) as Array<keyof typeof gameRule>)
        .filter((rule) => rule === "straightVariant")
        .map((straightVariant) => {
          const straightVariantDesc = ruleDescriptions.find(
            (ruleDesc) => ruleDesc.rule === straightVariant
          );

          return (
            <div
              className="flex justify-center items-center space-x-4 border-b w-full"
              key={straightVariant}
            >
              <p className="flex-grow text-left">{straightVariantDesc?.text}</p>
              <select
                value={options.gameRules.straightVariant}
                onChange={(e) => {
                  setOptions({
                    ...options,
                    gameRules: {
                      ...gameRule,
                      [straightVariant]: e.target.value,
                    },
                  });

                  setOptionsUpdated && setOptionsUpdated(true);
                }}
              >
                {(
                  Object.keys(straightVariants) as Array<
                    keyof typeof straightVariants
                  >
                )
                  .filter(
                    (variant) =>
                      allowedGameRules.includes(variant) ||
                      variant === "hongkong" ||
                      freeEvent
                  )
                  .map((variant) => (
                    <option key={variant} value={variant}>
                      {straightVariants[variant]}{" "}
                      {freeEvent &&
                        variant !== "hongkong" &&
                        ` - ${freeEventText}`}
                    </option>
                  ))}
              </select>
            </div>
          );
        })}
    </div>
  );
};

export default GameRulesList;

const straightVariants = {
  hongkong: "Hong Kong",
  vietnam: "Vietnam",
  malaysia: "Malaysia",
  indonesia: "Indonesia",
};
