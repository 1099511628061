import React, { useEffect, useState } from "react";
import { AuthState } from "types";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Cart from "./Cart";
import CheckoutForm from "./CheckoutForm";
import { useQuery } from "react-query";

type Props = { authState: AuthState; refreshAuthState: () => void };

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
);

const Checkout: React.FC<Props> = ({ authState, refreshAuthState }) => {
  const [showCheckout, setShowCheckout] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [userProducts, setUserProducts] = useState<any|never>([]);
  const productsResult = useQuery("products", () =>
    fetch("https://api.stripe.com/v1/products", {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_TEST_KEY}`,
      },
    }).then((res) => res.json())
  );

  const { data: products } = productsResult

  const pricesResult = useQuery("prices", () =>
    fetch("https://api.stripe.com/v1/prices", {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_TEST_KEY}`,
      },
    }).then((res) => res.json())
  );

  const { data: prices } = pricesResult;


  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };
  useEffect(() => {
    refreshAuthState();
  }, [refreshAuthState]);

  useEffect(() => {
    if (!(pricesResult.isLoading && productsResult.isLoading)) {
      setUserProducts(
        authState.payload?.cart.map((priceId: string) => {
          return products?.data.find(
            (product: any) =>
              product.id ===
              prices?.data.find((p: any) => p.id === priceId)?.product
          );
        })
      );
    }
  }, [authState.isLoading, productsResult.isLoading, pricesResult.isLoading, ]); 

  useEffect(() => {
    // console.log("CARTXXXXXXXXX", authState.payload?.cart);
    if (authState.payload?.cart !== undefined && showCheckout) {
      fetch(`${process.env.REACT_APP_API_URL}/user/create-payment-intent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ items: authState.payload?.cart }),
      })
        .then((res) => {
          console.log(res);
          return res.json();
        })
        .then((data) => {
          console.log("cliensecret", data);
          setClientSecret(data.clientSecret);
        })
        .catch((e) => console.error(e));
    }
  }, [showCheckout]);

  return (
    <div>
      <Cart
        prices={prices}
        products={products}
        authState={authState}
        refreshAuthState={refreshAuthState}
        userProducts={userProducts}
      />
      {clientSecret ? (
        <Elements stripe={stripePromise} options={options}>
          {/* <pre className="text-xs">{JSON.stringify(authState, null, 2)}</pre> */}
          {authState.payload?.cart ? (
            <div>{showCheckout && <CheckoutForm userProducts={userProducts} authState={authState} />}</div>
          ) : (
            <div>Your cart is empty!</div>
          )}
        </Elements>
      ) : (
        <div className="">
          <button
            className="bg-purple-400"
            onClick={() => setShowCheckout(true)}
          >
            Checkout
          </button>
        </div>
      )}
    </div>
  );
};

export default Checkout;
