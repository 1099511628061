import React from "react";
import { handleLeaveGame } from "api";
import { useHistory } from "react-router-dom";

interface Props {
  clientId: string;
  gameId?: string;
}

const LeaveButton: React.FC<Props> = ({ clientId, gameId }) => {
  const history = useHistory();
  
  return (
    <button
      className="p-1 pr-2 bg-gray-200 border border-gray-300 my-1 rounded hover:bg-gray-100"
      onClick={() => {
        if (gameId) {
          handleLeaveGame(clientId, gameId);
        }
        history.push("/game");
      }}
    >
      <div className="flex">
        <svg
          className="h-6 w-4 my-auto"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
        <div className="my-auto">Leave</div>
      </div>
    </button>
  );
};

export default LeaveButton;
