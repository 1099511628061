import Hand from "components/Hand";
import React, { useEffect, useState } from "react";
import { GameState, Player } from "types";

interface Props {
  gameState: GameState;
  playHistory: { player: string; cards: string[] }[];
  players: { [clientId: string]: Player };
}

const PlayHistory: React.FC<Props> = ({ gameState, playHistory, players }) => {
  const [currentPlay, setCurrentPlay] = useState(playHistory.length - 1);

  useEffect(() => {
    if (playHistory.length === 1 || currentPlay === playHistory.length - 2)
      setCurrentPlay(playHistory.length - 1);
    //currentPlay doesn't belong in this dependency array.
  }, [playHistory]);

  return (
    <div className="flex flex-col space-y-1">
      <p className="mx-auto font-bold">Turn {currentPlay + 1}</p>
      <p className="mx-auto">
        {players[playHistory[currentPlay]?.player]?.name} played
      </p>

      {gameState.options.playHistory?.state && (
        <>
          <div className="flex space-x-2 mx-auto">
            <button
              onClick={() =>
                currentPlay > 0
                  ? setCurrentPlay(0)
                  : setCurrentPlay(currentPlay)
              }
            >
              <svg
                className={`${
                  currentPlay !== 0 && playHistory.length !== 0
                    ? "text-gray-500 hover:text-gray-800"
                    : "text-gray-200"
                } h-6 w-6 `}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <button
              onClick={() =>
                currentPlay > 0
                  ? setCurrentPlay(currentPlay - 1)
                  : setCurrentPlay(currentPlay)
              }
            >
              <svg
                className={`${
                  currentPlay !== 0 && playHistory.length !== 0
                    ? "text-gray-500 hover:text-gray-800"
                    : "text-gray-200"
                } h-6 w-6 `}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
            <button
              onClick={() =>
                currentPlay < playHistory.length - 1
                  ? setCurrentPlay(currentPlay + 1)
                  : setCurrentPlay(currentPlay)
              }
            >
              <svg
                className={`${
                  currentPlay !== playHistory.length - 1 &&
                  playHistory.length !== 0
                    ? "text-gray-500 hover:text-gray-800"
                    : "text-gray-200"
                } h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
            <button
              onClick={() =>
                currentPlay < playHistory.length - 1
                  ? setCurrentPlay(playHistory.length - 1)
                  : setCurrentPlay(currentPlay)
              }
            >
              <svg
                className={`${
                  currentPlay !== playHistory.length - 1 &&
                  playHistory.length !== 0
                    ? "text-gray-500 hover:text-gray-800"
                    : "text-gray-200"
                } h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </>
      )}
      <div className="flex mx-auto space-x-1 w-48 justify-center">
        {playHistory.length !== 0 ? (
          <Hand cards={playHistory[currentPlay]?.cards} />
        ) : (
          <div className="flex flex-col justify-center h-24 w-96 bg-gray-200 rounded">
            <p className="text-center text-sm px-4">
              No cards have been played yet
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlayHistory;
