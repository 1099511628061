import React, { useEffect } from "react";

const HorizontalAd = () => {
  useEffect(() => {
    if (typeof window.aipDisplayTag !== "undefined") {
      window.aiptag.cmd.display.push(function () {
        window.aipDisplayTag.display("daidi-io_970x250");
      });
    }
  }, [])

  return (
    <div className="my-12 mx-4">
      <div className="flex justify-center h-full">
        <div id="daidi-io_970x250">
        </div>
      </div>
    </div>
  );
};

export default HorizontalAd;
