import Eye from "components/svg/Eye";
import Rank from "components/Rank";
import React from "react";
import { GameState } from "types";

interface Props {
  clientId: string;
  gameState: GameState;
  users: any;
  spectator: boolean;
  showCards: boolean;
  setShowCards: React.Dispatch<React.SetStateAction<boolean>>;
  textCenter?: boolean;
  textRight?: boolean;
}

const Plaque: React.FC<Props> = ({
  clientId,
  gameState,
  users,
  spectator,
  showCards,
  setShowCards,
  textCenter,
  textRight,
}) => {
  const { host, players } = gameState;

  return (
    <div
      className={`flex flex-col ${textRight ? "items-end" :
        textCenter ? "items-center" : "items-start"
      } justify-center`}
    >
      <Rank rating={users[clientId]?.rating} />
      <div
        className={`${host === clientId ? "text-purple-400" : ""} ${
          textRight ? "text-right w-12" : textCenter ? "text-center" : "w-12"
        } font-semibold text-2xl `}
      >
        <div className={`${textRight ? "float-right" : ""}`}>
          {players[clientId].name}
        </div>
      </div>
      <div className="flex justify-center items-center space-x-2 sm:w-full w-12 z-10">
        {spectator && (
          <button onClick={() => setShowCards(!showCards)}>
            {showCards ? <Eye open={false} /> : <Eye open />}
          </button>
        )}
        <div className="flex space-x-1 text-sm">
          <div className="font-bold">Score </div>
          <div className="">{players[clientId].score}</div>
        </div>
      </div>
      <div className={`${textRight ? "text-right" : ""} w-12`}>
        <div className={`${textRight ? "float-right" : ""}`}>
          {players[clientId].connected ? "" : "Disconnected"}
        </div>
      </div>
    </div>
  );
};

export default Plaque;
