import { useEffect, useState } from "react";
import { info } from "./cards.json";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Card, GameState } from "./types";
import GameWrapper from "features/Game/GameWrapper";
import Home from "features/Home/Home";
import Navbar from "components/Navbar/Navbar";
import SignUp from "features/Auth/SignUp";
import SignIn from "features/Auth/SignIn";
import { useAuth } from "helper/functions";
import ResetPassword from "features/Auth/ResetPassword";
import ResetPasswordRequest from "features/Auth/ResetPasswordRequest";
import ProtectedRoute from "features/Auth/ProtectedRoute";
import User from "features/User/User";
import ErrorMessage from "components/ErrorMessage";

import ProductList from "features/Shop/ProductList";
import Checkout from "features/Shop/Checkout";
import VerticalAd from "components/Ads/VerticalAd";
import HorizontalAd from "components/Ads/HorizontalAd";
import DropdownAd from "components/Ads/DropdownAd";

const queryClient = new QueryClient();

export const cardInfo: Card = info as Card;

function App() {
  const { authState, setAuthState, refreshAuthState } = useAuth();
  const useError = useState("");
  const useGameState = useState<GameState | null>(null);
  const useClientId = useState("");

  const [gameState] = useGameState;
  const [clientId] = useClientId;
  const [error] = useError;

  useEffect(() => {
    refreshAuthState();
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Router>

          <div className="flex flex-col items-center">
            <ErrorMessage useError={useError} />
          </div>
          <Navbar
            error={error}
            authState={authState}
            setAuthState={setAuthState}
            gameState={gameState}
            clientId={clientId}
          />
          {/* <DropdownAd /> */}
          <div
            className="hidden sm:block ml-4"
            style={{ width: "970", height: "250px" }}
          >
            <HorizontalAd />
          </div>
          <div className="w-full h-12"></div>
          <div className="mx-auto bg-gray-100">
            <div className="flex">
              <div
                className="hidden sm:block md:ml-4"
                style={{ width: "160px", height: "600px" }}
              >
                <VerticalAd />
              </div>
              <div className="flex-grow">
                <Switch>
                  <Route exact path="/" render={() => <Home />} />
                  <Route
                    path="/game/"
                    render={() => (
                      <GameWrapper
                        useError={useError}
                        authState={authState}
                        useGameState={useGameState}
                        useClientId={useClientId}
                      />
                    )}
                  />
                  <Route
                    path="/signup"
                    render={() => (
                      <SignUp
                        authState={authState}
                        setAuthState={setAuthState}
                      />
                    )}
                  />
                  <Route
                    path="/signin"
                    render={() => (
                      <SignIn
                        authState={authState}
                        setAuthState={setAuthState}
                      />
                    )}
                  />
                  <Route
                    path="/resetpassword/:hash"
                    render={() => <ResetPassword authState={authState} />}
                  />
                  <Route
                    path="/resetpassword"
                    render={() => (
                      <ResetPasswordRequest authState={authState} />
                    )}
                  />
                  <ProtectedRoute
                    authState={authState}
                    path="/perks"
                    render={() => (
                      <ProductList
                        authState={authState}
                        refreshAuthState={refreshAuthState}
                      />
                    )}
                  />
                  <ProtectedRoute
                    authState={authState}
                    path="/user"
                    render={() => <User authState={authState} />}
                  />
                  <ProtectedRoute
                    authState={authState}
                    path="/checkout"
                    render={() => (
                      <Checkout
                        authState={authState}
                        refreshAuthState={refreshAuthState}
                      />
                    )}
                  />
                </Switch>
                <Footer />
              </div>
              <div
                className="hidden md:block mr-4"
                style={{ width: "160px", height: "600px" }}
              >
                <VerticalAd />
              </div>
            </div>
          </div>
        </Router>
      </QueryClientProvider>
    </>
  );
}

export default App;
