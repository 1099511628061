import Button from "components/Button";
import React, { useEffect, useState } from "react";
import { GameState } from "types";

interface Props {
  gameState: GameState;
  setShowAd: React.Dispatch<React.SetStateAction<boolean>>;
}

const VideoAd: React.FC<Props> = ({ setShowAd }) => {
  const [allowClose, setAllowClose] = useState(false)

  useEffect(() => {
    window.aiptag.cmd.player.push(function () {
      window.aiptag.adplayer = new window.aipPlayer({
        AD_WIDTH: 960,
        AD_HEIGHT: 540,
        AD_DISPLAY: "default", //default, fullscreen, center, fill
        LOADING_TEXT: "loading advertisement",
        PREROLL_ELEM: function () {
          return document.getElementById("preroll");
        },
        AIP_COMPLETE: function (evt: any) {
          /*******************
           ***** WARNING *****
           *******************
           Please do not remove the PREROLL_ELEM
           from the page, it will be hidden automaticly.
          */
          console.log("Preroll Ad Completed: " + evt);
          setAllowClose(true)
        },
      });
    });
  }, []);

  useEffect(() => {
    if (typeof window.aiptag.adplayer !== "undefined") {
      window.aiptag.cmd.player.push(function () {
        window.aiptag.adplayer.startPreRoll();
      });
    } else {
      //Adlib didnt load this could be due to an adblocker, timeout etc.
      //Please add your script here that starts the content, this usually is the same script as added in AIP_COMPLETE.
      console.log("Ad Could not be loaded, load your content here");
      setAllowClose(true)
    }
  }, []);

  return (
    <div className="fixed w-full left-0 top-0 z-30 bg-black bg-opacity-30">  
      <div className="flex space-y-2 justify-center items-center h-screen p-2">
        <div className="shadow-lg p-2 bg-white border border-gray-100 rounded">
          <button className="flex gap-x-2" disabled={!allowClose} onClick={() => setShowAd(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${allowClose ? "bg-red-500 h-6 hover:bg-red-400" : "bg-red-200 cursor-default"} p-1 rounded-full text-red-50 text-right transition-colors w-6`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            {allowClose? "You may now close this ad." : "Thank you for taking the time to watch this ad."}
          </button>
          <div className="m-2" style={{ width: "960px", height: "560px" }}>
            <div className="flex justify-center h-full relative">
              <div
                className="bg-gray-50 flex flex-col justify-center items-center absolute"
                style={{ width: "960px", height: "540px" }}
                id="end"
              >
                <p className="text-xl">Our servers run on ad revenue, please consider disabling adblock!</p>
                <p className="my-4">
                  {allowClose ? "You may now close this ad." : "Thank you for taking the time to watch this ad."}  
                </p>
                <div>
                  <Button disabled={!allowClose} onClick={() => setShowAd(false)}>
                    Close
                  </Button>
                </div>
              </div>
              <div id="preroll"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoAd;
