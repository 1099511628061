import { suitSymbols } from "components/Card";
import React, { useRef, useState } from "react";
import { CardOrdering, FiveCardHands, Options, Ranks, Suits } from "types";
import CardOrderOption from "./CardOrderOption";

type Props = {
  cardOrdering: CardOrdering;
  options: Options;
  setOptions: React.Dispatch<React.SetStateAction<Options>>;
  setOptionsUpdated?: React.Dispatch<React.SetStateAction<boolean>>;
};

const CardOrderOptionList: React.FC<Props> = ({
  cardOrdering,
  options,
  setOptions,
  setOptionsUpdated,
}) => {
  const { suitOrder, rankOrder, fiveCardHandOrder } = cardOrdering;
  const [dragging, setDragging] = useState(false);
  const dragItem = useRef<string | null>(null);
  const dragItemNode = useRef<EventTarget | null>(null);
  const [collapse, setCollapse] = useState(true)

  // initiates drag event on the element in question (option)
  function handleDragStart(
    e: React.DragEvent<HTMLDivElement>,
    orderType: string
  ) {
    dragItemNode.current = e.target;
    dragItemNode.current?.addEventListener("dragend", handleDragEnd);
    dragItem.current = orderType;
    // hack to do something, TODO: lookup  what.
    setTimeout(() => {
      setDragging(true);
    }, 0);
  }
  function handleDragEnter(
    e: React.DragEvent<HTMLDivElement>,
    orderType: keyof Suits | keyof FiveCardHands | keyof Ranks
  ) {
    // console.log(
    //   dragItem.current,(e.target as any).innerText,orderType,
    //   "suit:",
    //   isSuitOption(dragItem.current as any),
    //   "fiveCardHand:",
    //   isFiveCardHandOption(dragItem.current as any),
    //   "rank:",
    //   isRankOption(dragItem.current as any)
    // );
    // get location of item
    // const cardIndex = hand.indexOf(orderTypeCard);
    if (isSuitOption(dragItem.current as any) && isSuitOption(orderType)) {
      if (dragItemNode.current !== e.target && dragItem.current) {
        const currentSuitIndex = suitOrder[dragItem.current as keyof Suits];

        // console.log((e.target as HTMLElement).innerText, dragItem.current);
        const newSuitRules = {
          ...suitOrder,
          [dragItem.current]: suitOrder[orderType],
          [orderType]: currentSuitIndex,
        };

        setOptions({
          ...options,
          cardOrdering: { ...options.cardOrdering, suitOrder: newSuitRules },
        });
      }
    }

    if (isRankOption(dragItem.current as any) && isRankOption(orderType)) {
      if (dragItemNode.current !== e.target && dragItem.current) {
        const currentSuitIndex = rankOrder[dragItem.current as keyof Ranks];

        // console.log((e.target as HTMLElement).innerText, dragItem.current);
        const newRankRules = {
          ...rankOrder,
          [dragItem.current]: rankOrder[orderType as keyof Ranks],
          [orderType as keyof Ranks]: currentSuitIndex,
        };

        setOptions({
          ...options,
          cardOrdering: { ...options.cardOrdering, rankOrder: newRankRules },
        });
      }
    }

    if (
      isFiveCardHandOption(dragItem.current as any) &&
      isFiveCardHandOption(orderType)
    ) {
      if (dragItemNode.current !== e.target && dragItem.current) {
        const currentSuitIndex =
          fiveCardHandOrder[dragItem.current as keyof FiveCardHands];

        // console.log((e.target as HTMLElement).innerText, dragItem.current);
        const newFiveCardHandRules = {
          ...fiveCardHandOrder,
          [dragItem.current]:
            fiveCardHandOrder[orderType as keyof FiveCardHands],
          [orderType as keyof FiveCardHands]: currentSuitIndex,
        };

        setOptions({
          ...options,
          cardOrdering: {
            ...options.cardOrdering,
            fiveCardHandOrder: newFiveCardHandRules,
          },
        });
      }
    }
  }

  // ends dragging event
  function handleDragEnd(e: Event) {
    setDragging(false);
    setOptionsUpdated && setOptionsUpdated(true);
    dragItem.current = null;
    dragItemNode.current?.removeEventListener("dragend", handleDragEnd);
    dragItemNode.current = null;
  }

  return (
    <div className="border-b">
      <div className="text-left">Card Ordering Options</div>
      <div
        className={`${
          collapse ? "max-h-14" : ""
        } flex space-x-2 overflow-y-hidden transition-all ease-out duration-75`}
      >
        <div className="flex-grow">
          <p className="">Suits</p>
          <CardOrderOption
            cardOrderType={suitOrder}
            handleDragStart={handleDragStart}
            handleDragEnter={handleDragEnter}
            dragging={dragging}
            dragItem={dragItem}
          />
        </div>
        <div className="flex-grow">
          <p>Ranks</p>
          <CardOrderOption
            cardOrderType={rankOrder}
            handleDragStart={handleDragStart}
            handleDragEnter={handleDragEnter}
            dragging={dragging}
            dragItem={dragItem}
          />
        </div>
        <div className="flex-grow">
          <p>Tricks</p>
          <CardOrderOption
            cardOrderType={fiveCardHandOrder}
            handleDragStart={handleDragStart}
            handleDragEnter={handleDragEnter}
            dragging={dragging}
            dragItem={dragItem}
          />
        </div>
        <div className="font-bold text-lg mt-5">High</div>
      </div>
      <button
        onClick={() => setCollapse(!collapse)}
        className="w-full transition hover:bg-gray-200 rounded text-center mt-2"
      >
        {collapse ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 mx-auto"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 mx-auto"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 15l7-7 7 7"
            />
          </svg>
        )}
      </button>
    </div>
  );
};

export default CardOrderOptionList;

export function isSuitOption(
  option: keyof Suits | keyof Ranks | keyof FiveCardHands
): option is keyof Suits {
  return ["diamonds", "spades", "clubs", "hearts"].includes(
    option as keyof Suits
  );
}
export function isFiveCardHandOption(
  option: keyof Suits | keyof Ranks | keyof FiveCardHands
): option is keyof Suits {
  return [
    "straight",
    "flush",
    "fullHouse",
    "fourOfAKind",
    "straightFlush",
  ].includes(option as keyof Suits);
}
export function isRankOption(
  option: keyof Suits | keyof Ranks | keyof FiveCardHands
): option is keyof Suits {
  return [
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "J",
    "Q",
    "K",
    "A",
    "2",
  ].includes(option as keyof Suits);
}
