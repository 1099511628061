import Rank from "components/Rank";
import React from "react";
import { AuthState } from "types";
import { useQuery } from "react-query";

const User: React.FC<{ authState: AuthState }> = ({ authState }) => {
  const { payload } = authState;
  const { data, isLoading } = useQuery("allowedRules", () =>
    fetch(`${process.env.REACT_APP_API_URL}/user/rules`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    }).then((res) => res.json())
  );
  
  if (!payload) return null;
  if (isLoading) return null;

  return (
    <div className="container mx-auto max-w-5xl flex flex-col gap-4">
      <div className="font-semibold mt-4 text-3xl">{payload.username}</div>
      <div className="flex items-center space-x-2">
        <div className="font-bold">Rating</div>
        <div>{payload.rating}</div>
        <Rank rating={payload.rating} />
      </div>
      <div className="flex items-center space-x-2">
        <div>Email</div>
        <div>
          {payload.email} {payload.verified ? "(Verified)" : "(Unverifed)"}
        </div>
      </div>
      <div>
        {/* <div>Purchased Rules</div>
        {data.allowedGameRules?.map((rule: any) => {
          return (
            <div key={`${rule.gameRule}.${rule.description}`}>
              {rule.gameRule} {rule.description}
            </div>
          );
        })} */}
      </div>
    </div>
  );
};

export default User;
