import React, { ReactText, useState, useEffect } from "react";
import { GameState, Options, Setting } from "types";

interface Props {
  setOptions: React.Dispatch<React.SetStateAction<Options>>;
  setOptionsUpdated?: React.Dispatch<React.SetStateAction<boolean>>;
  optionType: keyof Options;
  optionInfo: {
    state: boolean;
    text: string;
    value?: number;
  };
  options: Options;
  clientId?: string;
  gameState?: GameState;
}

const timeLimitDenomination = [
  { dName: "Blitz", value: 5 },
  { dName: "Quick", value: 15 },
  { dName: "Standard", value: 30 },
  { dName: "Chill", value: 60 },
];

const OptionButton: React.FC<Props> = ({
  options,
  optionType,
  optionInfo,
  setOptions,
  setOptionsUpdated,
  clientId,
  gameState,
}) => {
  console.log(clientId, gameState?.host)
  return (
    <div className="flex justify-center items-center space-x-4 border-b w-full">
      <p className="flex-grow text-left">{optionInfo.text}</p>
      {optionInfo.value !== undefined && (
        <OptionValue
          options={options}
          optionType={optionType}
          optionInfo={optionInfo}
          setOptions={setOptions}
          value={optionInfo.value || 0}
          denominations={timeLimitDenomination}
          setOptionsUpdated={setOptionsUpdated}
          clientId={clientId}
          gameState={gameState}
        />
      )}
      <button
        onClick={() => {
          setOptions({
            ...options,
            [optionType]: { ...optionInfo, state: !optionInfo.state },
          });

          setOptionsUpdated && setOptionsUpdated(true);
        }}
        className={`px-1 border-2 rounded mt-1 ${
          optionInfo.state
            ? "bg-green-500 border-green-600 text-green-100"
            : "bg-gray-500 text-gray-100 border-gray-600"
        }`}
      >
        {optionInfo.state ? "On" : "Off"}
      </button>
    </div>
  );
};

const OptionValue: React.FC<{
  value: number;
  setOptions: React.Dispatch<React.SetStateAction<Options>>;
  setOptionsUpdated?: React.Dispatch<React.SetStateAction<boolean>>;
  optionType: keyof Options;
  optionInfo: Setting;
  options: Options;
  denominations: { dName: string; value: number }[];
  clientId?: string;
  gameState?: GameState;
}> = ({
  value,
  setOptions,
  optionType,
  optionInfo,
  options,
  denominations,
  setOptionsUpdated,
  clientId,
  gameState,
}) => {
  const [index, setIndex] = useState(2);

  useEffect(() => {
    setOptions({
      ...options,
      [optionType]: {
        ...optionInfo,
        state: optionInfo.state,
        value: denominations[index].value,
      },
    });
  }, [index]);

  return (
    <div className="flex items-center">
      {
        <button
          disabled={clientId !== gameState?.host}
          onClick={() => {
            setIndex(index > 0 ? index - 1 : index);
            setOptionsUpdated && setOptionsUpdated(true);
          }}
        >
          <svg
            className={`${index <= 0 && "text-gray-200"} h-6 w-6 `}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
      }
      {clientId === gameState?.host ? (
        <div className="flex w-24 justify-center">
          <span className="font-bold">{denominations[index].dName} </span>{" "}
          {denominations[index].value}s
        </div>
      ) : (
        <div className="flex w-24 justify-center">
          <span className="font-bold">
            {
              denominations.find(
                (d) => d.value === (options[optionType] as Setting).value
              )?.dName
            }{" "}
          </span>{" "}
          ({(options[optionType] as Setting).value}s)
        </div>
      )}
      {
        <button
          disabled={clientId !== gameState?.host}
          onClick={() => {
            setIndex(index >= denominations.length - 1 ? index : index + 1);
            setOptionsUpdated && setOptionsUpdated(true);
          }}
        >
          <svg
            className={`${
              index >= denominations.length - 1 && "text-gray-200"
            } h-6 w-6`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      }
    </div>
  );
};

export default OptionButton;
