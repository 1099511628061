let HOST = process.env.REACT_APP_WEBSOCKET_URL || "ws://localhost:3001"
if (process.env.NODE_ENV === "production") {
  HOST = window.location.origin.replace(/^http/, 'ws')
}

const clientId = localStorage.getItem("clientId")

if (clientId !== undefined) {
  HOST = HOST + `/${clientId}`
}

export const ws = new WebSocket(HOST);
