import React from "react";
import { handleStartGame } from "api";
import JoinLink from "./JoinLink";
import { GameState } from "types";
import Rank from "components/Rank";

interface Props {
  clientId: string;
  gameState: GameState;
  users: any;
}

const LobbyInterface: React.FC<Props> = ({ clientId, gameState, users }) => {
  if (gameState?.spectatorIds.includes(clientId)) return null;

  return (
    <div className="flex-grow">
      <div className="flex flex-col space-y-2 my-2">
        <h2 className="text-center text-3xl font-semibold">
          Lobby - {gameState.gameName}
        </h2>
        {gameState.clientIds.map((cId) => (
          <div
            key={cId}
            className="w-full p-4 rounded bg-white shadow flex items-baseline space-x-2"
          >
            <Rank rating={users[cId]?.rating} />
            <h3 className="font-semibold text-2xl">
              {gameState.players[cId].name}
            </h3>
            {gameState.host === cId && <h4>Host</h4>}
            {cId === clientId && <h4>You</h4>}
          </div>
        ))}
        {[...Array(4 - gameState.clientIds.length)].map((_, i) => (
          <div
            key={i}
            className="w-full p-4 rounded bg-gray-200 text-gray-600 items-baseline space-x-2 text-2xl"
          >
            Player {i+gameState.clientIds.length+1}
          </div>
        ))}
      </div>
      <div>
        {gameState?.host === clientId && (
          <button
            className="px-2 py-1 bg-gray-700 rounded border text-gray-100 w-full"
            onClick={() => handleStartGame(clientId, gameState?.id)}
          >
            Start game
          </button>
        )}
        <JoinLink gameId={gameState.id} />
      </div>
    </div>
  );
};

export default LobbyInterface;
