import React from "react";

interface Props {
  onClick: () => void;
  disabled?: boolean;
}

const MenuButton: React.FC<Props> = (props) => {
  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled}
      className="bg-blue-500 font-semibold hover:bg-blue-400 mt-1 px-2 py-1 rounded-full text-blue-50 text-center transition-colors uppercase w-56 "
    >
      {props.children}
    </button>
  );
};

export default MenuButton;
