import { cardInfo } from "App";
import React from "react";
import CardBack from "./CardBack";

const translate = [
  "translate-y-2",
  "translate-y-0.5",
  "",
  "translate-y-0.5",
  "translate-y-2",
];
const rotate = ["-rotate-12", "-rotate-6", "", "rotate-6", "rotate-12"];

interface Props {
  n: number
}
const Hand: React.FC<Props> = ({ n }) => {
  const cards = []
  for (let i = 0; i < n; i++) {
    cards.push('card')
  }
  let hand;
  if (n === 1) {
    hand = (
      <CardBack
        xsmall
      />
    );
  }

  if (n === 2) {
    hand = cards.map((c, i) => (
      <div key={i} className={`transform ${translate[i * 2 + 1]} ${rotate[i * 2 + 1]}`}>
        <CardBack xsmall  />
      </div>
    ));
  }

  if (n === 3) {
    hand = cards.map((c, i) => (
      <div key={i} className={`transform ${translate[i + 1]} ${rotate[i + 1]}`}>
        <CardBack xsmall />
      </div>
    ));
  }

  if (n === 5) {
    hand = cards.map((c, i) => (
      <div key={i} className={`transform ${translate[i]} ${rotate[i]}`}>
        <CardBack xsmall />
      </div>
    ));
  }

  return <div className="flex -space-x-3 transform">{hand}</div>;
};

export default Hand;
