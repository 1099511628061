import { useQuery } from "react-query";
import { AuthState } from "types";

// type Perk = {
//   perkId: string;
//   name: string;
//   price: number;
//   description: string;
//   gameRule?: keyof GameRules;
// };

// type PerkList = Perk[];
/* flushSuitPriority: boolean;
      lowestCardGoesFirst: boolean;
      mustPlayLowestCard: boolean;
      disableSuits: boolean; */
// const perks: PerkList = [
//   {
//     perkId: "",
//     name: "Malaysia Straight Variant",
//     gameRule: "straightVariant",
//     price: 5,
//     description: "Malaysia straight variant",
//   },
//   {
//     perkId: "",
//     name: "Indonesia Straight Variant",
//     gameRule: "straightVariant",
//     price: 5,
//     description: "Indonesia straight variant",
//   },
//   {
//     perkId: "",
//     name: "Vietnam Straight Variant",
//     gameRule: "straightVariant",
//     price: 5,
//     description: "vietnam straight variant",
//   },
//   {
//     perkId: "",
//     name: "Rank Suit Priority",
//     gameRule: "flushSuitPriority",
//     price: 1,
//     description: "Rank will take priority over suit.",
//   },
//   {
//     perkId: "",
//     name: "Disable Suits",
//     gameRule: "flushSuitPriority",
//     price: 1,
//     description: "Must beat players using higher ranked cards.",
//   },
//   {
//     perkId: "",
//     name: "Membership",
//     price: 15,
//     description: "Include all",
//   },
// ];

function ProductList({ authState, refreshAuthState }: { authState: AuthState; refreshAuthState: () => void }) {
  const { isLoading, data: prices } = useQuery("prices", () =>
    fetch("https://api.stripe.com/v1/prices", {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_TEST_KEY}`,
      },
    }).then((res) => res.json())
  );

  const {data: products } = useQuery(
    "products",
    () =>
      fetch("https://api.stripe.com/v1/products", {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_TEST_KEY}`,
        },
      }).then((res) => res.json())
  );

  async function addToCart(priceId: string) {
    await fetch(`${process.env.REACT_APP_API_URL}/user/cart/${priceId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      body: JSON.stringify({ username: authState.payload?.username }),
    }).then(() => refreshAuthState());
  }

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="w-64">
      {/* <pre className="text-xs">{JSON.stringify(prices, null, 2)}</pre>
      <pre className="text-xs">{JSON.stringify(products, null, 2)}</pre> */}
      {products?.data.map((product: any) => (
        <div key={product.id} className="m-2 p-2 bg-white shadow rounded">
          <div>{product.name}</div>
          <div>{product.description}</div>
          <div>
            {prices.data
              .filter((price: any) => price.product === product.id)
              .map((price: any) => (
                <div key={price.id}>
                  <div>{price.unit_amount}</div>
                  <button onClick={() => {addToCart(price.id)}}>+ Cart</button>
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProductList;
