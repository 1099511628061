export const suits = ["diamonds", "clubs", "hearts", "spades"];
export const ranks = [
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "J",
  "Q",
  "K",
  "A",
  "2",
];

export const ruleDescriptions: {
  rule: string;
  text: string;
  description: string;
  default: string;
  todo?: boolean;
}[] = [
  {
    rule: "flushSuitPriority",
    text: "Flush Suit Priority",
    description:
      "Winning flush is determined by suit. When disabled, the winning suit is determined by the high card rank.",
    default: "true",
  },
  {
    rule: "lowestCardGoesFirst",
    text: "Lowest Card Goes First",
    description:
      "At the start of every game, the player with the lowest card goes first. When disabled, the winner of the previous game goes first.",
    default: "true",
    todo: true,
  },
  {
    rule: "mustPlayLowestCard",
    text: "Must Play Lowest Card",
    description:
      "If a current player has the lowest card, default: 3 of diamonds, that card must be played. When disabled, the player may does not need to play the lowest card on their turn.",
    default: "true",
    todo: true,
  },
  {
    rule: "reverseOrderNextGame",
    text: "Reverse Order Next Game",
    description:
      "At the start of the next game, reverse the orders of the next players. When disabled, maintain the same order.",
    default: "false",
    todo: true,
  },
  {
    rule: "disableSuits",
    text: "Disable Suits",
    description:
      "A hand must be beaten using a higher rank. It may no longer be beaten using a card of the same rank but higher suit. When disabled, a hand of the same rank may be beated with a higher suit.",
    default: "false",
  },
  {
    rule: "straightVariant",
    text: "Straight Variant",
    description: "Configure regional behavior for straight trick hierarchy.",
    default: "hongkong",
  },
];
