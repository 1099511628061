import React from "react";
import { info as cardInfo } from "../cards.json";
import { suitSymbols } from "./Card";

function roundHundred(value: number) {
  return Math.floor(value / 100) * 100;
}

const getRatingSymbol = (rating?: number) => {
  const roundedRating = rating || rating === 0 ? roundHundred(rating) : -1;
  switch (roundedRating) {
    case 0:
      return "Jd";
    case 100:
      return "Qd";
    case 200:
      return "Kd";
    case 300:
      return "Ad";
    case 400:
      return "2d";
    case 500:
      return "Jc";
    case 600:
      return "Qc";
    case 700:
      return "Kc";
    case 800:
      return "Ac";
    case 900:
      return "2c";
    case 1000:
      return "Jh";
    case 1100:
      return "Qh";
    case 1200:
      return "Kh";
    case 1300:
      return "Ah";
    case 1400:
      return "2h";
    case 1500:
      return "Js";
    case 1600:
      return "Qs";
    case 1700:
      return "Ks";
    case 1800:
      return "As";
    case 1900:
      return "2s";
    default:
      return "3d";
  }
};

const Rank = ({ rating }: { rating?: number }) => {
  const ratingSymbol = cardInfo[getRatingSymbol(rating)];

  return (
    <div
      className={`flex text-xl font-bold ${
        ratingSymbol.suit === "diamonds" || ratingSymbol.suit === "hearts"
          ? "text-red-600"
          : "text-black"
      }`}
    >
      <div>{ratingSymbol.rank}</div>
      <div>{suitSymbols[ratingSymbol.suit]}</div>
    </div>
  );
};

export default Rank;
