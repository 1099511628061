
import Rank from "components/Rank";
import React, { useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { AuthState, GameState } from "types";

interface Props {
  authState: AuthState;
  setAuthState: React.Dispatch<React.SetStateAction<AuthState>>;
  gameState: GameState | null;
  clientId: string;
  error: string;
}

const Navbar: React.FC<Props> = ({ authState, setAuthState, gameState, clientId, error }) => {
  const { id }: { id: string } = useParams();
  const history = useHistory();
  const location = useLocation()
  const [gameId] = useState(id ? id : "");
  function handleSignOut() {
    localStorage.removeItem("jwt");
    setAuthState({ isAuthenticated: false, isLoading: false });
  }

  let navState;

  if (authState.isAuthenticated) {
    navState = (
      <>
        <div className="ml-auto flex space-x-2">
          {/* <Link to="/checkout">Cart {authState.payload?.cart.length}</Link> */}
          <Link to="/user">{authState.payload?.username}</Link>
        </div>
        <Rank rating={authState.payload?.rating} />
        <button className="" onClick={handleSignOut}>
          Sign Out
        </button>
      </>
    );
  } else {
    navState = (
      <>
        <div className="ml-auto">
          <Link to="/signin">Sign In</Link>
        </div>
        <div>
          <Link to="/signup">Sign Up</Link>
        </div>
      </>
    );
  }

  return (
    <div className="w-full py-2 px-4 h-12 max-w-5xl mx-auto bg-white space-x-4 fixed left-0 right-0 z-10 shadow-sm">
      <div className="flex w-full space-x-4 items-center">
        <div className="flex space-x-2">
          <button onClick={() => history.push("/")}>
          <Rank rating={1900}></Rank>

          </button>
          {/* <Link to="/perks"><button>Perks</button></Link> */}
        </div>
        <div className="flex-grow">
          {error === "Not connected to the server" &&
            (location.pathname === "/game" ? (
              <>
              Not Connected... 
              <button
                className="mx-2 px-2 bg-red-500 rounded hover:bg-red-400 text-red-200"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Refresh
              </button>
              </>
            ) : (
              <a href="/game">
                <button className="mx-2 px-2 bg-red-500 rounded hover:bg-red-400 text-red-200">
                  Return to game
                </button>
              </a>
            ))}
        </div>
        <div className="flex space-x-2 items-center">{navState}</div>
      </div>
    </div>
  );
};

export default Navbar;
