import Card from "components/Card";
import React from "react";

interface Props {
  suit?: string;
  rank?: string;
  prefix?: string;
  suffix?: string
}

const CardDescriptor: React.FC<Props> = ({suit, rank, prefix, suffix}) => {
  return (
    <div className="text-lg flex items-center space-x-2">
      <p>{prefix}</p>
      <div className="inline">
        <Card xsmall suit={suit || ""} rank={rank || ""} />
      </div>
      <p>{suffix}</p>
    </div>
  );
};

export default CardDescriptor