import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { AuthState } from "types";

const ResetPassword = ({ authState }: { authState: AuthState }) => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { isValid },
    getValues
  } = useForm();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { hash } = useParams<{ hash: string }>();

  useEffect(() => {
    if (authState.isAuthenticated) {
      history.push("/game");
    }
  }, [authState.isAuthenticated]);

  const onSubmit = async (data: any) => {
    const token = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/pwreset`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          hash: hash,
          password: data.password
        }),
      }
    )
      .then((response) => response.json())
      .then(() => setShowConfirmation(true))
      .catch((e) => console.error(e));
  };
  return (
    <div className="max-w-5xl mx-auto px-2">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Reset your password
        </h2>
      </div>
      {showConfirmation ? (
        <p className="text-center my-5">
          Your password has been reset. Click{" "}
          <Link to="/signin">here</Link> to log in!
        </p>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password (8 characters min)
            </label>
            <div className="mt-1">
              <input
                {...register("password", {
                  required: true,
                  minLength: {
                    value: 8,
                    message: "min length is 8",
                  },
                })}
                id="password"
                type="password"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium text-gray-700"
            >
              Confirm Password
            </label>
            <div className="mt-1">
              <input
                {...register("confirmPassword", {
                  required: true,
                  validate: (value) => value === getValues("password"),
                })}
                id="confirmPassword"
                type="password"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className={`bg-indigo-600 hover:bg-indigo-700 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
              Reset Password
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ResetPassword;
