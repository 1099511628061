import React, { useState, useEffect } from "react";
import { ws } from "config";
import Card, { suitSymbols } from "../../components/Card";
import { cardInfo } from "../../App";
import PlayerHand from "./PlayerHand";
import { suits } from "helper/constants";
import { ascRank, ascSuit, playerHasFreebie } from "helper/functions";
import PlayHistory from "./PlayerInterface/PlayHistory";
import { GameState } from "types";
import Hand from "components/Hand";
import Rank from "components/Rank";
import { handlePlayGame } from "api";

interface Props {
  cards: string[];
  clientId: string;
  gameId: string;
  currentPlayer: string;
  gameState: GameState;
  users: any;
  setPlayModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CardList: React.FC<Props> = ({
  cards,
  clientId,
  gameId,
  currentPlayer,
  gameState,
  users,
  setPlayModal
}) => {
  const [cardsToPlay, setCardsToPlay] = useState<string[]>([]);
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [hand, setHand] = useState<string[]>(gameState.players[clientId].cards);

  useEffect(() => {
    if (currentPlayer === clientId) {
      setIsPlaying(false)
    }
  }, [currentPlayer])

  useEffect(() => {
    const newHand = hand.filter((card) => cards.includes(card));
    if (newHand.length === cards.length) {
      setHand(newHand);
    } else {
      setHand(cards);
    }
  }, [cards]);

  // attach websocket
  useEffect(() => {
    ws.addEventListener("message", (message) => {
      const { type, payload } = JSON.parse(message.data);
      if (type && type === "update") {
        
        if (payload.gameState?.currentPlayer !== clientId) {
          setCardsToPlay([]);
        }
      }
    });
  }, []);

  //handles what cards are shown in hand
  function handleCardsToPlay(card: string) {
    setIsPlaying(false)
    if (cardsToPlay.includes(card)) {
      const newCards = cardsToPlay.filter((c) => c !== card);
      setCardsToPlay(newCards);
    } else if (currentPlayer === clientId) {
      setCardsToPlay([...cardsToPlay, card]);
    }
  }

  function handleSort(sortType: string): void {
    if (sortType === "suit") {
      const newHand = [...hand.sort(ascRank).sort(ascSuit)];
      setHand(newHand);
    }

    if (sortType === "rank") {
      const newHand = [...hand.sort(ascSuit).sort(ascRank)];
      setHand(newHand);
    }
  }

  return (
    <div className="max-w-5xl mx-auto">
      <button className="z-10 xl:hidden" onClick={() => {setPlayModal(false)}}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="bg-red-600 h-8 hover:bg-red-500 border-2 border-red-900 mx-2 my-1 opacity-100 p-1 rounded-full text-white transition-colors w-8"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div className="pb-4 pt-2 xl:hidden bg-green-800 mx-2 mb-1 rounded-2xl shadow-xl flex flex-col items-center">
        <div className="flex">
          <h2 className="uppercase text-green-50 font-bold">Current Play</h2>
        </div>
        <div className="h-20 flex items-center">
          {gameState.lastPlayed.cards.length !== 0 ? (
            <Hand cards={gameState.lastPlayed.cards} />
          ) : (
            <p className="text-center uppercase text-green-50 font-semibold px-4 my-auto">
              No cards have been played
            </p>
          )}
        </div>
      </div>
      {gameState.currentPlayer === clientId && (
        <>
          <div
            className={`${
              gameState.lastPlayed.player === clientId
                ? "bg-green-600"
                : "bg-green-800"
            } mx-2 p-2 mb-1 rounded-2xl shadow-xl`}
          >
            <div className="text-center font-bold text-xl text-green-50">
              Your turn
              {gameState.lastPlayed.player === clientId &&
              gameState.lastPlayed.cards.length !== 0 ? (
                <> (Freebie)</>
              ) : (
                <></>
              )}
            </div>
            <div className="flex h-40 justify-center items-center">
              {cardsToPlay.map((card) => (
                <div key={card}>
                  <div
                    className="sm:mx-1 -mx-6 transform cursor-pointer transition-all ease-out duration-75 hover:-translate-y-3"
                    onClick={() => handleCardsToPlay(card)}
                    key={`${cardInfo[card].rank}${cardInfo[card].suit}`}
                  >
                    <Card
                      rank={cardInfo[card].rank}
                      suit={cardInfo[card].suit}
                    />
                  </div>
                  <div className="absolute w-full h-6 bottom-0 inset-x-0"></div>
                </div>
              ))}
            </div>
            <div className="text-center">
              <button
                className={`font-semibold mx-auto my-2 px-12 py-1 rounded-full text-xl uppercase transition-colors ${
                  isPlaying ? "opacity-50" : ""
                } ${
                  cardsToPlay.length
                    ? "bg-green-300 text-green-900"
                    : "bg-green-900 text-green-50"
                }`}
                onClick={() => {
                  handlePlayGame(clientId, gameId, cardsToPlay);
                  setIsPlaying(true);
                }}
                disabled={isPlaying}
              >
                {cardsToPlay.length ? "Play" : "Pass"}
              </button>
            </div>
          </div>
        </>
      )}
      <div className="bg-white mb-1 mt-2 mx-2 rounded-2xl shadow-xl">
        <div className="flex space-x-2 text-xl font-semibold justify-center mx-2">
          <Rank rating={users[clientId]?.rating} />
          <div>{gameState.players[clientId].name}</div>
          <div>{gameState.players[clientId].score}</div>
        </div>
        <div className="flex justify-center py-1 items-center space-x-2">
          <div className="my-1 font-bold text-center uppercase text-lg text-black">
            Sort
          </div>
          <button
            className="px-3 py-1 rounded-full bg-gray-100 uppercase font-semibold text-xl flex space-x-1 justify-center items-center"
            onClick={() => handleSort("suit")}
          >
            <div className="text-red-600">{suitSymbols["diamonds"]}</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
            <div>{suitSymbols["spades"]}</div>
          </button>
          <button
            className="px-3 py-1 rounded-full bg-gray-100 uppercase font-semibold text-xl flex space-x-1 justify-center items-center"
            onClick={() => handleSort("rank")}
          >
            <div>3</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
            <div>2</div>
          </button>
        </div>
      </div>
      <PlayerHand
        cardsToPlay={cardsToPlay}
        hand={hand}
        setHand={setHand}
        handleCardsToPlay={handleCardsToPlay}
      />
    </div>
  );
};

export default CardList;
