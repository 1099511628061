import { suitSymbols } from "components/Card";
import Hand from "components/Hand";
import { suits } from "helper/constants";
import React, { useState } from "react";
import CardDescriptor from "./CardDescriptor";

const RanksAndSuits = () => (
  <div className="flex justify-center flex-col space-y-4 items-center">
    <p className="text-lg">Ranks have priority over suits.</p>
    <div className="text-lg flex space-x-4">
      <div className="my-auto font-bold">Low</div>
      <p className="md:text-2xl text-base my-auto">3 4 5 6 7 8 9 10 J Q K A 2</p>
      <div className="my-auto font-bold">High</div>
    </div>
    <div className="text-lg flex space-x-4">
      <div className="my-auto font-bold">Low</div>
      {suits.map((suit) => (
        <div
          key={suit}
          className={`font-semibold text-2xl ${
            suit === "diamonds" || suit === "hearts" ? "text-red-600" : ""
          }`}
        >
          {suitSymbols[suit]}
        </div>
      ))}
      <div className="my-auto font-bold">High</div>
    </div>
  </div>
);

const TwoSpades = () => (
  <div className="flex justify-center flex-col space-y-4 items-center">
    <p className="text-lg">
      Since <span className="font-bold">2</span> is the highest rank, and ♠ is
      the highest suit, that means
    </p>
    <CardDescriptor
      suffix="is the strongest single card."
      rank="2"
      suit="spades"
    />
    <p className="text-lg">When is card is played as a single, in a pair or triple, the player is immediately given a freebie.</p>
  </div>
);

const Straight = () => (
  <div className="flex justify-center flex-col space-y-4 items-center">
    <p className="text-lg">
      A Straight is 5 cards with consecutive ranks. It loses to all other tricks.
    </p>
    <Hand cards={["6d", "7s", "8d", "9c", "10h"]} />
  </div>
);

const Flush = () => (
  <div className="flex justify-center flex-col space-y-4 items-center">
    <p className="text-lg">
      A Flush is 5 cards of the same suit. This beats Straights and loses to Full Houses and stronger.
    </p>
    <Hand cards={["3c", "7c", "10c", "Jc", "Kc"]} />
  </div>
);

const FullHouse = () => (
  <div className="flex justify-center flex-col space-y-4 items-center">
    <p className="text-lg">
      A Full House is 3 cards of the same rank paired with 2 cards of the same rank. This beats Flushes, and weaker and loses to
      Four of a Kind and stronger.
    </p>
    <Hand cards={["3c", "3h", "5c", "5s", "5d"]} />
  </div>
);

const FourOfAKind = () => (
  <div className="flex justify-center flex-col space-y-4 items-center">
    <p className="text-lg">
      A Four Of A Kind is 4 cards of the same rank paired with 1 other card.
      This beats Full Houses and weaker, and loses to a Straight Flush.
    </p>
    <Hand cards={["8c", "8h", "8d", "8s", "5d"]} />
  </div>
);

const StraightFlush = () => (
  <div className="flex justify-center flex-col space-y-4 items-center">
    <p className="text-lg">
      A Straight Flush is 5 cards of consecutive rank with the same suit. This beats all other tricks.
    </p>
    <Hand cards={["6h", "7h", "8h", "9h", "10h"]} />
  </div>
);

const CardsAndTricks = () => {
  const [value, setValue] = useState("Straight");

  return (
    <div className="flex justify-center flex-col space-y-8 items-center">
      <h2 className="text-3xl font-semibold text-center">Cards and Tricks</h2>
      <select
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="text-2xl bg-white shadow-inner rounded border-gray-200 border-2 pr-4"
      >
        <option className="text-base" value="RanksAndSuits">
          Ranks and Suits
        </option>
        <option className="text-base" value="TwoSpades">
          2♠
        </option>
        <option className="text-base" value="Straight">
          Straight
        </option>
        <option className="text-base" value="Flush">
          Flush
        </option>
        <option className="text-base" value="FullHouse">
          Full House
        </option>
        <option className="text-base" value="FourOfAKind">
          Four Of A Kind
        </option>
        <option className="text-base" value="StraightFlush">
          Straight Flush
        </option>
      </select>
      {value === "RanksAndSuits" && <RanksAndSuits />}
      {value === "TwoSpades" && <TwoSpades />}
      {value === "Straight" && <Straight />}
      {value === "Flush" && <Flush />}
      {value === "FullHouse" && <FullHouse />}
      {value === "FourOfAKind" && <FourOfAKind />}
      {value === "StraightFlush" && <StraightFlush />}
    </div>
  );
};

export default CardsAndTricks;
