import React, { useState, useEffect } from "react";
import { ws } from "config";
import { handleJoinGame, handleSpectateGame } from "api";
import { AuthState, GameState } from "types";

interface Props {
  clientId: string;
  name: string;
  authState: AuthState
}

const Browser: React.FC<Props> = ({ clientId, name, authState }) => {
  const [games, setGames] = useState<{ [gameId: string]: GameState }>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ws.addEventListener("message", (message) => {
      const { type, payload } = JSON.parse(message.data);
      if (type === "getGames") {
        setGames(payload.games);
        setLoading(false)
      }
    });
  }, [])

  useEffect(() => {
    if (ws.readyState === 1 && clientId) {
      handleGetGames()
    }
    //don't change this dependency array or it will prevent browser from auto refreshing on launch
  }, [ws.readyState])

  function handleGetGames(): void {
    setLoading(true)
    const result = {
      type: "getGames",
      payload: {
        clientId: clientId,
      },
    };
    ws.send(JSON.stringify(result));
  }

  return (
    <div className="bg-white rounded border mt-4 p-2 max-w-5xl mx-auto">
      <div className="grid grid-cols-3 text-right gap-2">
        <div className="text-center mb-2 col-start-2">Game Browser</div>
        <div className="">
          <button className="text-gray-600" onClick={handleGetGames}>
            &#8635;
          </button>
        </div>
      </div>
      <div className="grid gap-2 grid-cols-5 text-center text-sm font-bold">
        <div>Game Name</div>
        <div>Players</div>
        <div>Spectators</div>
        <div>Status</div>
        <div></div>
        <hr className="col-span-5" />
        {!!Object.keys(games).length ? (
          Object.keys(games).map((gameId) => (
            <div key={gameId} className="col-span-5 grid grid-cols-5 border-b p-1 font-normal">
              <div>{games[gameId].gameName}</div>
              <div>{games[gameId].clientIds.length}/4</div>
              <div>{games[gameId].spectatorIds.length}</div>
              <div>{games[gameId].startGame ? "In Progress" : "Lobby"}</div>
              <div className="grid grid-cols-2">
                {!games[gameId].startGame && (
                  <div>
                    <button
                      className="rounded text-blue-500 hover:text-blue-400 font-bold"
                      onClick={() => handleJoinGame(clientId, gameId, name, authState.payload?.username)}
                    >
                      Join
                    </button>
                  </div>
                )}
                <div>
                  <button
                    className="rounded text-blue-500 hover:text-blue-400 font-bold"
                    onClick={() => handleSpectateGame(clientId, gameId)}
                  >
                    Spectate
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center font-normal bg-gray-100 text-gray-600 p-2 col-span-5">
            {loading ? (
              <div>Refreshing...</div>
            ) : (
              <button onClick={handleGetGames}>No games &#8635;</button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Browser;
