import { handleStartGame, handleReturnToLobby, handleLeaveGame } from "api";
import MenuButton from "components/MenuButton";
import { gameEnded } from "helper/functions";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AuthState, GameState, Options } from "types";
import CurrentGameOptions from "../CurrentGameOptions";

interface Props {
  clientId: string;
  gameState: GameState;
  options: Options;
  setOptions: React.Dispatch<React.SetStateAction<Options>>;
  authState: AuthState;
}

const GameMenu: React.FC<Props> = ({
  clientId,
  gameState,
  options,
  setOptions,
  authState,
}) => {
  const [collapse, setCollapse] = useState(true);
  const history = useHistory();
  const { id }: { id: string } = useParams();
  const [gameId] = useState(id ? id : "");
  const [showRules, setShowRules] = useState(false);

  useEffect(() => {
    if (
      gameEnded(gameState) &&
      gameState.startGame &&
      gameState.host === clientId
    ) {
      setCollapse(false);
    }
  }, [gameEnded(gameState)]);

  return (
    <>
      <div className="max-w-screen-lg mx-auto relative">
        <button
          className={`${
            collapse ? "" : "ring-2"
          } focus:outline-none fixed bg-white m-2 p-2 sm:right-32 right-4 rounded shadow-xl top-14 z-30 border-gray-300 border`}
          onClick={() => setCollapse(!collapse)}
        >
          {collapse ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          )}
        </button>
      </div>
      {!collapse && (
        <>
          {showRules && (
            <div className="fixed w-full left-0 top-0 z-30">
              <div className="flex space-y-2 justify-center items-center h-screen p-2">
                <div className="shadow-lg p-2 bg-white border border-gray-100 rounded">
                  <button onClick={() => setShowRules(false)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="bg-red-500 h-6 hover:bg-red-400 p-1 rounded-full text-red-50 text-right transition-colors w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                  <div className="border border-gray-100">
                    <CurrentGameOptions
                      options={options}
                      setOptions={setOptions}
                      clientId={clientId}
                      authState={authState}
                      gameState={gameState}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="fixed w-full left-0 top-0 z-20">
            <div className="flex space-y-2 justify-center items-center h-screen p-2">
              <div className="bg-gray-50 p-2 rounded-lg shadow-2xl">
                <div className="">
                  <button onClick={() => setCollapse(true)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="bg-red-500 h-6 hover:bg-red-400 p-1 rounded-full text-red-50 text-right transition-colors w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="flex flex-col p-4 space-y-4 ">
                  {clientId === gameState.host && (
                    <>
                      <MenuButton
                        onClick={() => handleStartGame(clientId, gameState.id)}
                      >
                        New Game
                      </MenuButton>
                      <MenuButton
                        onClick={() =>
                          handleReturnToLobby(clientId, gameState.id)
                        }
                      >
                        Return to Lobby
                      </MenuButton>
                    </>
                  )}
                  <MenuButton onClick={() => setShowRules(true)}>
                    View Game Options
                  </MenuButton>
                  <MenuButton
                    onClick={() => {
                      if (gameId) {
                        handleLeaveGame(clientId, gameId);
                      }
                      history.push("/game");
                    }}
                  >
                    Leave Game
                  </MenuButton>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default GameMenu;
