import React from "react";
import { GameState } from "types";
import East from "../PlayerInfo/East";
import North from "../PlayerInfo/North";
import South from "../PlayerInfo/South";
import West from "../PlayerInfo/West";
import PlayHistory from "../PlayerInterface/PlayHistory";

interface Props {
  gameState: GameState;
  users:any;
}

const SpectatorView: React.FC<Props> = ({ gameState, users }) => {
  const { clientIds, players, host, startGame } = gameState;
  return (
    <div className="flex flex-col items-center overflow-hidden xl:overflow-visible">
      {clientIds.length > 1 && clientIds[1] && (
        <North spectator players={players} clientId={clientIds[1]} host={host} gameState={gameState}
        users={users}/>
      )}
      <div className="flex w-full">
        {clientIds.length > 0 && clientIds[0] && (
          <West spectator players={players} clientId={clientIds[0]} host={host} gameState={gameState}
          users={users}/>
        )}
        <div className="flex-grow mt-16">
          {startGame && (
            <PlayHistory
            gameState={gameState}
              playHistory={gameState.playHistory}
              players={players}
            />
          )}
        </div>
        {clientIds.length > 2 && clientIds[2] && (
          <East spectator players={players} clientId={clientIds[2]} host={host} gameState={gameState}
          users={users}/>
        )}
      </div>
      {clientIds.length > 3 && clientIds[3] && (
        <South spectator players={players} clientId={clientIds[3]} host={host} gameState={gameState}
        users={users}/>
      )}
    </div>
  );
};

export default SpectatorView;
