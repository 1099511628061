import React from "react";

interface Props {
  small?: boolean;
  xsmall?: boolean;
}

export const suitSymbols: { [suit: string]: string } = {
  diamonds: "♦",
  clubs: "♣",
  hearts: "♥",
  spades: "♠",
};

const Card: React.FC<Props> = ({ small, xsmall }) => {
  if (xsmall) {
    return (
      <div
        className={`transform rotate-0 text-center rounded leading-none bg-red-700 shadow-md border-2 border-red-900`}
        style={{
          width: "3rem",
          height: "4.2rem",
        }}
      >
      </div>
    );
  }

  return (
    <div
      className={`transform rotate-0 grid grid-rows-3 grid-cols-3 text-center rounded leading-none bg-red-700 shadow-md border-4 border-red-900 ${small ? "text-base" : "text-xl"}`}
      style={{
        width: `${small ? "4rem" : "6rem"}`,
        height: `${small ? "5.6rem" : "8.4rem"}`,
      }}
    >
    </div>
  );
};

export default Card;
