import { handleJoinGame, handleLeaveGame, handleSpectateGame } from "api";
import Button from "components/Button";
import Header from "components/Header";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthState, GameState, Options } from "types";
import Game from "./Game";
import LeaveButton from "./LeaveButton";
interface Props {
  clientId: string;
  gameState: GameState | null;
  currentGameId: string | null;
  authState: AuthState;
  options: Options;
  setOptions: React.Dispatch<React.SetStateAction<Options>>;
}

const JoinGame: React.FC<Props> = ({
  clientId,
  gameState,
  currentGameId,
  authState,
  options,
  setOptions,
}) => {
  const { id }: { id: string } = useParams();
  const [gameId] = useState(id ? id : "");
  const [name, setName] = useState("");
  
  useEffect(() => {
    if (authState.payload) {
      setName(authState.payload?.username);
    }
  }, [authState.isAuthenticated]);

  useEffect(() => {
    window.onpopstate = () => {
      handleLeaveGame(clientId, gameId);
    };
  }, []);

  if (gameState)
    return (
      <Game
        clientId={clientId}
        gameState={gameState}
        currentGameId={currentGameId}
        authState={authState}
        options={options}
        setOptions={setOptions}
      />
    );

  return (
    <>
      <Header title="Join Game" />
      <LeaveButton clientId={clientId} />
      <div className="text-center text-lg">Big Two</div>
      <div className="flex justify-center">
        <div className="mt-2 flex flex-col space-y-4">
          <input
            type="text"
            placeholder="Player Name"
            className="px-4 py-3 text-lg border flex-shrink focus:outline-none focus:ring focus:border-blue-300"
            value={name}
            disabled={authState.isAuthenticated}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="flex">
            <Button onClick={() => handleJoinGame(clientId, gameId, name)}>
              Join game
            </Button>
            <Button onClick={() => handleSpectateGame(clientId, gameId)}>
              Spectate
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinGame;
