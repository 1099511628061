import { isAuthenticated } from "helper/functions";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, Redirect, useHistory } from "react-router-dom";
import { AuthState } from "types";

const SignIn = ({
  authState,
  setAuthState,
}: {
  authState: AuthState;
  setAuthState: React.Dispatch<React.SetStateAction<AuthState>>;
}) => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm();
  
  useEffect(() => {
    if (authState.isAuthenticated) {
      history.push("/");
    }
  }, [authState.isAuthenticated]);

  const onSubmit = async (data: any) => {
    const getAuthState = async (token: string) => {
      const authState = await isAuthenticated(token);
      setAuthState(authState);
    };
    await fetch(`${process.env.REACT_APP_API_URL}/auth/signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: data.username,
        password: data.password,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.token) {
          localStorage.setItem("jwt", json.token);
          getAuthState(json.token);
        } else {
          throw new Error("Bad username or password")
        }
      })
      .catch((e) => console.error(e.message));

    
  };

  return (
    <div className="max-w-5xl mx-auto px-2">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div>
          <label
            htmlFor="username"
            className="block text-sm font-medium text-gray-700"
          >
            Username
          </label>
          <div className="mt-1">
            <input
              {...register("username", { required: true })}
              id="username"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            Password
          </label>
          <div className="mt-1">
            <input
              {...register("password", { required: true })}
              id="password"
              type="password"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        <p>Forgot your password? Click <Link to="/resetpassword">here</Link> to reset</p> 
        <div>
          <button
            type="submit"
            className={`bg-indigo-600 hover:bg-indigo-700 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          >
            Sign in
          </button>
        </div>
      </form>
    </div>
  );
};

export default SignIn;
