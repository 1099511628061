import { handlePlayGame } from "api";
import { ascSuit, ascRank, playerHasFreebie } from "helper/functions";
import React, { useEffect, useState } from "react";
import { GameState } from "types";

const Timer: React.FC<{
  clientId: string;
  gameState: GameState;
  timeLimit: number;
}> = ({ clientId, gameState, timeLimit }) => {
  const [seconds, setSeconds] = useState(0);
  const { currentPlayer, players, lastPlayed, id: gameId } = gameState;
  const [isActive, setIsActive] = useState(currentPlayer === clientId);
  const lowestCard = players[clientId].cards.sort(ascSuit).sort(ascRank)[0];

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    }
    if (seconds > timeLimit) {
      if (playerHasFreebie(gameState) || lastPlayed.player === clientId) {
        handlePlayGame(clientId, gameId, [lowestCard]);
      } else {
        handlePlayGame(clientId, gameId, []);
      }
    }

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <div className="fixed top-16 w-full left-0 z-20">
      <div
        className={`bg-white rounded-full h-20 w-20 flex items-center justify-center mx-auto  ${
          seconds / timeLimit > 0.75
            ? "border-red-500 border-8"
            : "border-green-500 border-4"
        }`}
      >
        <div className="text-3xl font-bold">{timeLimit - seconds}</div>
      </div>
    </div>
  );
};

export default Timer;
