import Debug from "components/Debug";
import Navbar from "components/Navbar/Navbar";
import ErrorMessage from "components/ErrorMessage";
import { ws } from "config";
import SignIn from "features/Auth/SignIn";
import SignUp from "features/Auth/SignUp";
import { isAuthenticated } from "helper/functions";
import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { AuthState, GameState, Options } from "types";
import CreateGame from "./CreateGame";
import JoinGame from "./JoinGame";
import ResetPasswordRequest from "features/Auth/ResetPasswordRequest";
import ResetPassword from "features/Auth/ResetPassword";

function GameWrapper({
  authState,
  useError,
  useGameState,
  useClientId
}: {
  authState: AuthState;
  useError: [string, React.Dispatch<React.SetStateAction<string>>];
  useGameState: [
    GameState | null,
    React.Dispatch<React.SetStateAction<GameState | null>>
  ];
  useClientId: [string, React.Dispatch<React.SetStateAction<string>>];
}) {
  const [clientId, setClientId] = useClientId;
  const [gameState, setGameState] = useGameState;
  const [error, setError] = useError;
  const [currentGameId, setCurrentGameId] = useState(null);

  //Game Options
  const [options, setOptions] = useState<Options>({
    showCards: { state: true, text: "Show Remaining Cards" },
    publicGame: { state: true, text: "Public Game" },
    playHistory: { state: false, text: "Play History" },
    timeLimit: { state: false, value: 30, text: "Turn Time Limit" },
    cardOrdering: {
      suitOrder: {
        diamonds: 0,
        clubs: 1,
        hearts: 2,
        spades: 3,
      },
      rankOrder: {
        "3": 0,
        "4": 1,
        "5": 2,
        "6": 3,
        "7": 4,
        "8": 5,
        "9": 6,
        "10": 7,
        J: 8,
        Q: 9,
        K: 10,
        A: 11,
        "2": 12,
      },
      fiveCardHandOrder: {
        straight: 0,
        flush: 1,
        fullHouse: 2,
        fourOfAKind: 3,
        straightFlush: 4,
      },
    },
    gameRules: {
      flushSuitPriority: true,
      lowestCardGoesFirst: true,
      mustPlayLowestCard: true,
      reverseOrderNextGame: false,
      disableSuits: false,
      straightVariant: "hongkong"
    },
    // spectator: { state: false, text: "Spectator Mode" },
    // rotateLoser: { state: true, text: "Rotate Loser" },
  });

  useEffect(() => {
    ws.onopen = () => {
      setError("");
    };

    ws.addEventListener("message", (message) => {
      const { type, payload } = JSON.parse(message.data);

      if (type === "connect") {
        console.log("Connected as ", payload.clientId);
        localStorage.setItem("clientId", payload.clientId);
        setClientId(payload.clientId);
        if (payload.currentGameId) {
          setCurrentGameId(payload.currentGameId);
        }
      }

      if (type === "update") {
        setGameState(payload.gameState);
        if(payload.gameState?.options) {
          setOptions(payload.gameState.options)
        }
        setError("");
        setCurrentGameId(payload.currentGameId);
      }

      if (type === "error") {
        setError(payload.message);
      }
    });

    ws.onclose = () => {
      setError("Not connected to the server");
    };

    return () => {
      ws.close();
    };
  }, []);

  return (
    <>
      <div className="container mx-auto">
        {/* <Rules /> */}
        <Debug gameState={gameState} authState={authState} error={error} />
        <Switch>
          <Route
            exact
            path="/game/"
            render={() => (
              <CreateGame
                clientId={clientId}
                gameState={gameState}
                currentGameId={currentGameId}
                authState={authState}
                options={options}
                setOptions={setOptions}
              />
            )}
          />
          <Route
            path="/game/:id?"
            render={() => (
              <JoinGame
                clientId={clientId}
                gameState={gameState}
                currentGameId={currentGameId}
                authState={authState}
                options={options}
                setOptions={setOptions}
              />
            )}
          />
        </Switch>
      </div>
    </>
  );
}

export default GameWrapper;
