import React, { useEffect } from "react";

const VerticalAd = () => {
  useEffect(() => {
    window.aiptag.cmd.display.push(function () {
      window.aipDisplayTag.display("daidi-io_160x600");
    });
  }, []);
  return (
      <div className="flex justify-center h-full">
        {/* <div
          className="my-auto bg-gray-200  text-gray-600 font-semibold text-center"
          style={{ height: "600px", width: "160px" }}
        >
          Ad space
        </div> */}
        <div id="daidi-io_160x600"></div>
      </div>
  );
};

export default VerticalAd;
