import React from "react";

interface Props {
  rank?: string;
  suit?: string;
  dragging?: boolean;
  small?: boolean;
  xsmall?: boolean;
}

export const suitSymbols: { [suit: string]: string } = {
  diamonds: "♦",
  clubs: "♣",
  hearts: "♥",
  spades: "♠",
};

const CardNorth: React.FC<Props> = ({ rank, suit, small}) => {
  return (
    <div
      className={`transform rotate-0 grid grid-rows-3 grid-cols-3 text-center rounded leading-none bg-white shadow-md border border-gray-200 ${
        suit === "diamonds" || suit === "hearts" ? "text-red-600" : "text-black"
      } ${small ? "text-base" : "text-xl"}`}
      style={{
        width: `${small ? "4rem" : "6rem"}`,
        height: `${small ? "5.6rem" : "8.4rem"}`,
      }}
    >
      <div className="col-span-2"></div>
      <div className="grid grid-rows-2 m-auto">
        <div>{rank ? rank : "?"}</div>
        <div>{suit ? suitSymbols[suit] : "?"}</div>
      </div>
      <div></div>
      <div className={`m-auto transform rotate-180 ${small ? "text-3xl" : "text-4xl"}`}>
        {suit ? suitSymbols[suit] : "?"}
      </div>
      <div className=""></div>
      <div className="transform grid grid-rows-2 m-auto">
        <div>{rank ? rank : "?"}</div>
        <div>{suit ? suitSymbols[suit] : "?"}</div>
      </div>
    </div>
  );
};

export default CardNorth;
