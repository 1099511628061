import { handleUpdateRules } from "api";
import { isCardOrdering, isGameRules, isSetting } from "helper/functions";
import React, { useState } from "react";
import { Options, GameState, AuthState } from "types";
import CardOrderOptionList from "./CardOrderOptionList";
import GameRulesList from "./GameRulesList";
import OptionButton from "./OptionButton";

interface Props {
  options: Options;
  setOptions: React.Dispatch<React.SetStateAction<Options>>;
  authState: AuthState;
  gameState: GameState;
  clientId: string;
}

const CurrentGameOptions: React.FC<Props> = ({
  options,
  setOptions,
  authState,
  clientId,
  gameState,
}) => {
  const [optionsUpdated, setOptionsUpdated] = useState(false);

  return (
    <div className="flex mt-2">
      <div className="text-center mx-auto flex-col">
        <div className="text-sm text-center flex flex-col bg-white px-2 py-1 rounded shadow">
          <div className="text-center text-xl font-semibold">Options</div>
          {(Object.keys(options) as Array<keyof typeof options>).map(
            (option) => {
              const setting = options[option];

              return (
                <div key={option}>
                  {isSetting(setting) && (
                    <OptionButton
                      options={options}
                      optionType={option}
                      optionInfo={setting}
                      setOptions={setOptions}
                      setOptionsUpdated={setOptionsUpdated}
                      clientId={clientId}
                      gameState={gameState}
                    />
                  )}

                  {isCardOrdering(setting) && (
                    <CardOrderOptionList
                      cardOrdering={setting}
                      options={options}
                      setOptions={setOptions}
                      setOptionsUpdated={setOptionsUpdated}
                    />
                  )}

                  {isGameRules(setting) && (
                    <GameRulesList
                      gameRule={setting}
                      options={options}
                      setOptions={setOptions}
                      setOptionsUpdated={setOptionsUpdated}
                      authState={authState}
                    />
                  )}
                </div>
              );
            }
          )}
          {gameState.host === clientId && !gameState.startGame && (
            <button
              className={`${
                optionsUpdated
                  ? "bg-green-500 border-green-600 text-green-100"
                  : "bg-gray-500 border-gray-600 text-gray-100 disabled cursor-not-allowed"
              } mt-2 border rounded`}
              onClick={() => {
                handleUpdateRules(clientId, gameState.id, options);
                setOptionsUpdated(false);
              }}
            >
              Update Rules
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CurrentGameOptions;
