import { suitSymbols } from "components/Card";
import React from "react"
import { Suits, Ranks, FiveCardHands } from "types";
import { isFiveCardHandOption, isRankOption, isSuitOption } from "./CardOrderOptionList";

type Props = {
  cardOrderType: Suits | Ranks | FiveCardHands;
  handleDragStart: (e: React.DragEvent<HTMLDivElement>, orderType: string) => void;
  handleDragEnter: (e: React.DragEvent<HTMLDivElement>, orderType: keyof Suits | keyof FiveCardHands | keyof Ranks) => void;
  dragging: boolean;
  dragItem: React.MutableRefObject<string | null>;
};

const fiveCardToChinese = {
  straight: "蛇",
  flush: "花",
  fullHouse: "富佬",
  fourOfAKind: "四條",
  straightFlush: "同话順",
};

const fiveCardMap = {
  straight: "Straight",
  flush: "Flush",
  fullHouse: "Full House",
  fourOfAKind: "4 of a Kind",
  straightFlush: "Str. Flush",
};

const CardOrderOptionList: React.FC<Props> = ({cardOrderType, handleDragStart, handleDragEnter, dragging, dragItem}) => {
  return (
    <div className="flex flex-col space-y-1 bg-white overflow-auto text-lg text-center">
      {(Object.keys(cardOrderType) as Array<keyof typeof cardOrderType>)
        .sort((a, b) => cardOrderType[b] - cardOrderType[a])
        .map((delim) => (
          <div
            key={delim}
            onDragStart={(e) => handleDragStart(e, delim)}
            onDragEnter={
              dragging ? (e) => handleDragEnter(e, delim) : undefined
            }
            draggable
            className={`${
              dragItem.current === delim && dragging
                ? "shadow-lg bg-gray-300 opacity-75"
                : ""
            } border-2 rounded px-1 cursor-pointer`}
          >
            {isSuitOption(delim) && (
              <span
                className={`${
                  delim === "diamonds" || delim === "hearts"
                    ? "text-red-600"
                    : "text-black"
                }`}
              >
                {suitSymbols[delim]}
              </span>
            )}
            {isRankOption(delim) && <span>{delim}</span>}
            {isFiveCardHandOption(delim) && (
              <span>{fiveCardMap[delim]}</span>
            )}
          </div>
        ))}
    </div>
  );
}

export default CardOrderOptionList