import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const ErrorMessage: React.FC<{
  useError: [string, React.Dispatch<React.SetStateAction<string>>];
}> = ({  useError }) => {
  const [collapse, setCollapse] = useState(true);
  const [error, setError] = useError;
  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
    setCollapse(false);
  }, [error, location.pathname]);

  return (
    <div
      className={`flex mx-auto max-w-5xl items-center p-2 z-40 bg-red-200 fixed w-full text-red-700 border-l-2 border-red-400 transform transition-all duration-300 
        ${
          !collapse && error && location.pathname.includes("/game")
            ? "top-0"
            : "-top-12"
        }`}
    >
      <div className="flex-grow flex">
        <div className="">{error ? error : ""}</div>
        {error === "Not connected to the server" &&
          (location.pathname.includes("/game") ? (
            <button
              className="mx-2 px-2 bg-red-500 rounded hover:bg-red-400 text-red-200"
              onClick={() => {
                window.location.reload();
              }}
            >
              Refresh
            </button>
          ) : (
            <a href="/game">
              <button className="mx-2 px-2 bg-red-500 rounded hover:bg-red-400 text-red-200">
                Return to game
              </button>
            </a>
          ))}
      </div>
      <button className="my-auto mr-2" onClick={() => setCollapse(true)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  );
};

export default ErrorMessage;
