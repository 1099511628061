import React from "react";

interface Props {
  rank?: string;
  suit?: string;
  dragging?: boolean;
  small?: boolean;
  xsmall?: boolean;
}

export const suitSymbols: { [suit: string]: string } = {
  diamonds: "♦",
  clubs: "♣",
  hearts: "♥",
  spades: "♠",
};

const SideCard: React.FC<Props> = ({ rank, suit, dragging, small, xsmall }) => {
  if (xsmall) {
    return (
      <div
        className={`transform rotate-0 text-center rounded leading-none bg-white shadow-md border border-gray-200 ${
          suit === "diamonds" || suit === "hearts"
            ? "text-red-600"
            : "text-black"
        } text-3xl`}
        style={{
          width: "4.2rem",
          height: "3rem",
        }}
      >
        <div className="grid grid-rows-2 grid-cols-2 mt-2 ml-px -space-x-1 -space-y-4">
          <div
            className={`${
              rank === "10" ? "text-2xl tracking-tighter mb-px" : ""
            }`}
          >
            {rank ? rank : "?"}
          </div>
          <div />
          <div />
          <div>{suit ? suitSymbols[suit] : "?"}</div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`transform rotate-0 grid grid-rows-3 grid-cols-3 text-center rounded leading-none bg-white shadow-md border border-gray-200 ${
        suit === "diamonds" || suit === "hearts" ? "text-red-600" : "text-black"
      } ${small ? "text-base" : "text-xl"}`}
      style={{
        width: `${small ? "5.6rem" : "8.4rem"}`,
        height: `${small ? "4rem" : "6rem"}`,
      }}
    >
      <div className="col-span-2"></div>
      <div className="grid grid-cols-2 gap-1 m-auto my-auto">
        <div className="transform">
          {suit ? suitSymbols[suit] : "?"}
        </div>
        <div className="transform">{rank ? rank : "?"}</div>
      </div>
      <div></div>
      <div
        className={`m-auto transform ${
          small ? "text-3xl" : "text-4xl"
        }`}
      >
        {suit ? suitSymbols[suit] : "?"}
      </div>
      <div></div>
      <div className="grid grid-cols-2 gap-1 m-auto my-auto">
        <div className="transform ">{rank ? rank : "?"}</div>
        <div className="transform ">
          {suit ? suitSymbols[suit] : "?"}
        </div>
      </div>
    </div>
  );
};

export default SideCard;
