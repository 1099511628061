import React from "react";

interface Props {
  onClick: () => void;
  disabled?: boolean;
}

const Button: React.FC<Props> = (props) => {
  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled}
      className={`${
        props.disabled ? "bg-gray-400 cursor-not-allowed" : "bg-gray-700"
      } px-2 py-2 rounded border text-gray-100 flex-grow focus:outline-none hover:bg-gray-600 text-xl`}
    >
      {props.children}
    </button>
  );
};

export default Button;
