import HorizontalAd from "components/Ads/HorizontalAd";
import Card from "components/Card";
import Hand from "components/Hand";
import React, { useEffect, useState } from "react";
import CardDescriptor from "./CardDescriptor";
import CardsAndTricks from "./CardsAndTricks";

const UserIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    className={className}
  >
    <path
      fillRule="evenodd"
      d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
      clipRule="evenodd"
    />
  </svg>
);

const Home: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (currentIndex < 14) {
      timeout = setTimeout(() => {
        setCurrentIndex(currentIndex + 1);
      }, 50);
    }

    return () => clearTimeout(timeout);
  }, [currentIndex]);

  return (
    <div className="flex justify-center flex-col items-center bg-gray-100 max-w-4xl mx-auto p-4">
      <div className="w-full flex flex-col items-center space-y-20 py-12">
        <h1 className="text-5xl font-semibold">Big Two</h1>
        <div className="md:flex space-x-2 hidden">
          <Card rank="2" suit="spades" />
          <Card rank="2" suit="hearts" />
          <Card rank="2" suit="clubs" />
          <Card rank="2" suit="diamonds" />
        </div>
        <div className="flex space-x-2 md:hidden">
          <Card xsmall rank="2" suit="spades" />
          <Card xsmall rank="2" suit="hearts" />
          <Card xsmall rank="2" suit="clubs" />
          <Card xsmall rank="2" suit="diamonds" />
        </div>
        <a href="/game">
          <button className="bg-green-400 font-black hover:bg-green-300 my-2 px-8 py-4 rounded text-4xl text-green-800 tracking-widest border-4 border-green-500 transition-colors">
            PLAY
          </button>
        </a>
      </div>
      <div className="flex flex-col items-center space-y-16 w-full py-6">
        <div className="flex flex-col items-center space-y-8 leading-7 text-lg">
          <h2 className="text-3xl font-semibold text-center">DaiDi.io</h2>
          <p>
            Named after the Cantonese name for the Big Two card game, 锄大地
            (Cho Dai Di), DaiDi.io is an online platform for playing Big Two
            with others. Invite 2 or 3 other friends to play this popular card
            game!
          </p>
        </div>
        <div className="flex flex-col items-center space-y-8 leading-7">
          <h2 className="text-3xl font-semibold text-center">About Big Two</h2>
          <p>
            Big Two is a card game played using playing cards. It is very
            popular in East Asia and Southeast Asia, especially throughout
            mainland China, Hong Kong, Macau, Taiwan, Indonesia, the
            Philippines, Malaysia and Singapore. This game is also known as many
            other names, such as deuces and Chinese poker. In Mandarin, it is
            大老二, in Cantonese, 锄大地. It can also be known as pusoy dos
            (Philippines), 步步高升 (Taiwan).
          </p>
          <p>
            The game is named after its ranking system;{" "}
            <span className=" bg-white px-1 rounded shadow border border-gray-200">
              2
            </span>{" "}
            is the strongest rank in the game. It is usually played with 3 to 4
            players. Each player starts with the same number of cards, and must
            eliminate their entire hand to win. The player with the{" "}
            <span className="text-red-600 bg-white px-1 rounded shadow border border-gray-200">
              3♦
            </span>{" "}
            begins the game by playing it with other{" "}
            <span className="bg-white px-1 rounded shadow border border-gray-200">
              3s
            </span>{" "}
            or within a trick.
          </p>
          <p>
            Tricks are 5-card poker hands. You can play, from weakest to
            strongest, a straight, flush, full house, four of a kind, or
            straight flush. Tricks can only be played during a player's freebie,
            or when the previous play is also a trick. Similarly, a player must
            play the same cardinality as the previous play.
          </p>
          <p>
            There is also a hierarchy of suits,{" "}
            <span className=" bg-white px-1 rounded shadow border border-gray-200">
              ♠ Spades
            </span>{" "}
            are the strongest,{" "}
            <span className="text-red-600 bg-white px-1 rounded shadow border border-gray-200">
              ♥ Hearts
            </span>{" "}
            and{" "}
            <span className=" bg-white px-1 rounded shadow border border-gray-200">
              ♣ Clubs
            </span>{" "}
            follow, and{" "}
            <span className="text-red-600 bg-white px-1 rounded shadow border border-gray-200">
              ♦ Diamonds
            </span>{" "}
            are the weakest. Therefore, this makes the{" "}
            <span className=" bg-white px-1 rounded shadow border border-gray-200">
              2♠ Two of Spades
            </span>{" "}
            the strongest single card in the game. Players are given a freebie
            after playing this card.
          </p>
        </div>
        <HorizontalAd />
        <div className="flex flex-col items-center space-y-8">
          <div className="flex flex-col items-center space-y-4">
            <h2 className="text-3xl font-semibold text-center">How to play</h2>
            <p className="italic">
              As with many popular card games, there are regional deviations
              from these rules.
            </p>
            <h2 className="text-2xl underline text-center">Setup</h2>
            <div className="md:flex md:space-x-16">
              <div className="flex flex-col items-center">
                <p className="text-lg font-semibold">3 Players</p>
                <div className="flex -space-x-16">
                  <UserIcon className="w-24 h-24 mt-2 text-gray-500" />
                  <UserIcon className="w-28 h-28 text-gray-600 z-10" />
                  <UserIcon className="w-24 h-24 mt-2 text-gray-500" />
                </div>
                <p>
                  Every player starts with <span className="font-bold">17</span>{" "}
                  cards.
                </p>
                <p>
                  One random player gets{" "}
                  <span className="text-red-600 bg-white p-1 rounded shadow border border-gray-200">
                    3♦
                  </span>
                </p>
              </div>
              <div className="flex flex-col items-center">
                <p className="text-lg font-semibold">4 Players</p>
                <div className="flex -space-x-16 mt-2">
                  <UserIcon className="w-24 h-24 z-0 text-gray-500" />
                  <div className="flex flex-col items-center -space-y-16 -mt-2">
                    <UserIcon className="w-20 h-20 text-gray-400 -z-10" />
                    <UserIcon className="w-28 h-28 text-gray-600 z-10" />
                  </div>
                  <UserIcon className="w-24 h-24 z-0 text-gray-500" />
                </div>
                <p>
                  Every player starts with <span className="font-bold">13</span>{" "}
                  cards.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center space-y-4">
            <h2 className="text-2xl underline text-center">First Turn</h2>
            <p className="text-lg">
              The player with the{" "}
              <span className="text-red-600 bg-white p-1 rounded shadow border border-gray-200">
                3♦
              </span>{" "}
              plays first. This card must be included in the first play.
            </p>
            <div className="flex flex-col justify-center items-center rounded shadow-inner border-2 w-full p-4">
              <p className="text-lg">
                Here are examples of plays you can make:
              </p>
              <div className="flex flex-col md:flex-row items-center md:space-x-4 text-center">
                <div className="p-6 pt-3 rounded space-y-1">
                  <p className="font-bold">Single</p>
                  <Hand cards={["3d"]} />
                </div>
                <div className="p-6 pt-3 rounded space-y-1">
                  <p className="font-bold">Pair</p>
                  <Hand cards={["3d", "3s"]} />
                </div>
                <div className="p-6 pt-3 rounded space-y-1">
                  <p className="font-bold">Triple</p>
                  <Hand cards={["3d", "3c", "3s"]} />
                </div>
              </div>
              <div className="text-center">
                <div className="p-6 pt-3 rounded space-y-1">
                  <p>
                    <span className="font-bold">Any Trick</span> (ex. Straight)
                  </p>
                  <Hand cards={["3d", "4s", "5s", "6c", "7d"]} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center space-y-4">
            <h2 className="text-2xl underline text-center">Next Turn</h2>
            <p className="text-lg">
              You must beat the current play using the same number of cards, or
              pass.
            </p>
            <p className="text-lg">
              If you own the current play, i.e., if everyone passes on your
              play, you have a freebie.
            </p>
            <h2 className="text-2xl underline text-center">Win Condition</h2>
            <p className="text-lg">
              The first player to eliminate all of the cards from their hand
              wins.
            </p>
          </div>
          <div className="flex flex-col items-center space-y-4">
            <h2 className="text-2xl underline text-center">Scoring</h2>
            <p>
              When a player wins, all remaining players lose points based on the
              number of cards in their hand. Each card is valued at 1 point. If
              they have 10 or more cards, each card is valued at 2 points. If
              they failed to play any cards, each card is valued at 3 points.
              Any unplayed 2s are counted as double the points.
            </p>
          </div>
        </div>
        <HorizontalAd />
        <CardsAndTricks />
        {/* <div className="flex flex-col items-center space-y-4">
          <h2 className="text-xl font-semibold text-center">Edge Cases</h2>
          <h2 className="text-2xl underline text-center">Straights</h2>
          <div className="flex flex-col items-center justify-center space-y-4">
            <Hand cards={["Jh", "Qs", "Kh", "Ac", "2d"]} />
            <p className="italic text-lg">beats</p>
            <Hand cards={["Ad", "2s", "3h", "4c", "5d"]} />
            <p className="italic text-lg">beats</p>
            <Hand cards={["2c", "3c", "4d", "5h", "6d"]} />
            <p className="italic text-lg">beats</p>
            <Hand cards={["10s", "Jh", "Qd", "Kh", "Ad"]} />
          </div>
          <h2 className="text-2xl underline text-center">Flushes</h2>
          <p className="text-center">Suit takes priority.</p>
          <div className="flex items-center justify-center space-x-6">
            <Hand cards={["3s", "5s", "7s", "9s", "Js"]} />
            <p className="italic text-lg">beats</p>
            <Hand cards={["7d", "9d", "Jd", "Kd", "2d"]} />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Home;

/**
 * 2 of spades is the strongest card
 *
 * First Player
 * 3D
 * What can you play?
 * You can play Single, double, triple, five card hands
 *  these can be tabs of example plays
 */
