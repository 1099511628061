import React from "react";

interface Props {
  rank?: string;
  suit?: string;
  dragging?: boolean;
  small?: boolean;
  xsmall?: boolean;
}

export const suitSymbols: { [suit: string]: string } = {
  diamonds: "♦",
  clubs: "♣",
  hearts: "♥",
  spades: "♠",
};

const SideCard: React.FC<Props> = ({ rank, suit, dragging, small, xsmall }) => {
  if (xsmall) {
    return (
      <div
        className={`transform bg-red-700 rotate-0 text-center rounded leading-none shadow-md border-2 border-red-900 ${
          suit === "diamonds" || suit === "hearts"
            ? "text-red-600"
            : "text-black"
        } text-3xl`}
        style={{
          width: "4.2rem",
          height: "3rem",
        }}
      >
      </div>
    );
  }

  return (
    <div
      className={`transform bg-red-700 rotate-0 grid grid-rows-3 grid-cols-3 text-center rounded leading-none shadow-md border-4 border-red-900 ${
        suit === "diamonds" || suit === "hearts" ? "text-red-600" : "text-black"
      } ${small ? "text-base" : "text-xl"}`}
      style={{
        width: `${small ? "5.6rem" : "8.4rem"}`,
        height: `${small ? "4rem" : "6rem"}`,
      }}
    >
    </div>
  );
};

export default SideCard;
