import React, { useEffect, useState } from "react";
import useSound from "use-sound";
import { GameState } from "types";
import North from "./North";
import West from "./West";
import PlayHistory from "../PlayerInterface/PlayHistory";
import East from "./East";
import CardList from "../CardList";
import HandBack from "components/HandBack";
import Header from "components/Header";
import Timer from "./Timer";
const beep = require("../../../media/tone-beep.mp3");

interface Props {
  clientId: string;
  gameState: GameState;
  showCards: boolean;
  users:any;
}

const PlayerList: React.FC<Props> = ({ clientId, gameState, users }) => {
  const { clientIds, players, host, currentPlayer, startGame } = gameState;
  const [playModal, setPlayModal] = useState(false);
  const [tone] = useSound(beep.default);
  const clientIndex = clientIds.indexOf(clientId);
  const isSpectator = players[clientId].cards.length === 0
  useEffect(() => {
      
    if (clientId === currentPlayer) {
      tone();
    }
  }, [currentPlayer, clientId, tone]);
  return (
    <>
      <Header title={`${gameState.players[currentPlayer]?.name}'s turn`} />
      <div className="flex flex-col items-center overflow-hidden xl:overflow-visible">
        {clientIds.length >= 3 &&
          clientIds[(clientIndex + 2) % clientIds.length] && (
            <North
              players={players}
              clientId={clientIds[(clientIndex + 2) % clientIds.length]}
              host={host}
              gameState={gameState}
              users={users}
              spectator={isSpectator}
            />
          )}
        <div className="flex w-full">
          {clientIds.length >= 2 &&
            clientIds[(clientIndex + 1) % clientIds.length] && (
              <West
                players={players}
                clientId={clientIds[(clientIndex + 1) % clientIds.length]}
                host={host}
                gameState={gameState}
                users={users}
                spectator={isSpectator}
              />
            )}
          <div className="flex-grow mt-16">
            {startGame && (
              <PlayHistory
                gameState={gameState}
                playHistory={gameState.playHistory}
                players={players}
              />
            )}
          </div>
          {clientIds.length >= 4 &&
            clientIds[(clientIndex + 3) % clientIds.length] && (
              <East
                players={players}
                clientId={clientIds[(clientIndex + 3) % clientIds.length]}
                host={host}
                gameState={gameState}
                users={users}
                spectator={isSpectator}
              />
            )}
        </div>
        <div className="flex space-x-1 text-sm">
          <div className="font-bold">Score </div>
          <div className="">{players[clientId].score}</div>
        </div>
        <button
          className={`${
            clientId === gameState.currentPlayer ? "bg-green-400" : ""
          } my-4 p-4 relative rounded-3xl xl:hidden`}
          onClick={() => setPlayModal(true)}
        >
          <p className="absolute font-semibold left-0 m-auto right-0 text-red-50 top-1/3 uppercase z-10">
            Show Cards
          </p>
          <HandBack n={3} />
        </button>
        {playModal && (
          <div className="xl:hidden fixed z-20 w-full top-0 h-screen">
            {" "}
            <div className="w-full absolute bottom-2">
              <CardList
                gameState={gameState}
                cards={gameState.players[clientId].cards}
                clientId={clientId}
                gameId={gameState?.id}
                currentPlayer={gameState.currentPlayer}
                users={users}
                setPlayModal={setPlayModal}
              />
            </div>
            <div className="max-w-5xl mx-auto"></div>
          </div>
        )}

        <div className="-mt-28 hidden w-full xl:block">
          <CardList
            gameState={gameState}
            cards={gameState.players[clientId].cards}
            clientId={clientId}
            gameId={gameState?.id}
            currentPlayer={gameState.currentPlayer}
            users={users}
            setPlayModal={setPlayModal}
          />
        </div>
      </div>
      {currentPlayer === clientId && gameState.options.timeLimit?.state && (
        <Timer
          clientId={clientId}
          gameState={gameState}
          timeLimit={gameState.options.timeLimit.value || 30}
        />
      )}
    </>
  );
};

export default PlayerList;
