import { ws } from "config";
import { request } from "express";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import shortid from "shortid";
import { AuthState, GameState } from "types";

interface Message {
  timestamp: number;
  clientId: string;
  username: string;
  message: string;
}

const errorMessage = (message: string) => {
  return {
    timestamp: Date.now(),
    clientId: "Error",
    username: "Error",
    message,
  };
};

const Chatroom: React.FC<{
  clientId: string;
  authState: AuthState;
  gameState: GameState;
}> = ({ clientId, authState, gameState }) => {
  const [collapse, setCollapse] = useState(true);
  const [messages, setMessages] = useState<Message[]>([]);
  const [notify, setNotify] = useState(false)
  const [newMessage, setNewMessage] = useState<Message>();
  const [chatTimestamps, setChatTimestamps] = useState<number[]>([]);
  const [chatDisabled, setChatDisabled] = useState(false);
  const { register, handleSubmit, reset, formState } =
    useForm<{ message: string }>();

  useEffect(() => {
    ws.addEventListener("message", (message) => {
      const {
        type,
        payload,
      }: {
        type: string;
        payload: Message;
      } = JSON.parse(message.data);
      if (type === "chat") {
        setNotify(true)
        setNewMessage(payload);
      }
    });
  }, []);

  // before updating messages
  useEffect(() => {
    if (newMessage) {
      setMessages([...messages, newMessage]);
    }
  }, [newMessage]);

  //after updateing messages
  useEffect(() => {
    if (chatTimestamps.length > 4 && chatDisabled === false) {
      setMessages([
        ...messages,
        errorMessage(
          "You're sending messages too quickly, please wait to send more."
        ),
      ]);
      setChatDisabled(true);
    }
    const messagesView = document.getElementById("messages-view");
    if (messagesView) {
      messagesView.scrollTop = messagesView.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (chatTimestamps.length > 4) {
      setTimeout(() => {
        setChatTimestamps([]);
        setChatDisabled(false);
      }, 5000);
    }
    console.log(chatTimestamps);
  }, [chatTimestamps]);

  function sendChatMessage(
    clientId: string,
    gameId: string,
    message: string,
    username?: string
  ) {
    const result = {
      type: "chat",
      payload: {
        clientId: clientId,
        gameId: gameId,
        message: message,
        username: username,
      },
    };
    const now = Date.now();
    const duration = 5 * 1000;
    const newChatTimestamps = chatTimestamps.filter((t) => t > now - duration);
    if (newChatTimestamps.length < 6) {
      setChatTimestamps([Date.now(), ...newChatTimestamps]);
      ws.send(JSON.stringify(result));
    }
  }

  return (
    <>
      <div className="max-w-screen-lg mx-auto relative">
        <button
          className={`${
            collapse ? "" : "ring-2"
          } focus:outline-none fixed bg-white m-2 p-2 sm:right-32 right-4 rounded shadow-xl top-28 z-30 border-gray-300 border`}
          onClick={() => {
            setCollapse(!collapse);
            setNotify(false);
          }}
        >
          {notify && (
            <div className="absolute h-3 w-3 rounded-full -top-1 -right-1 bg-red-500"></div>
          )}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
            />
          </svg>
        </button>
      </div>
      {!collapse && (
        <div className="fixed bg-white sm:bottom-4 sm:right-32 bottom-0 z-40 rounded-t border border-b-0 shadow-lg">
          <div className="flex w-full items-center border-b">
            <div className="flex">
              <button className="p-1" onClick={() => setCollapse(!collapse)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="bg-red-500 h-6 hover:bg-red-400 p-1 rounded-full text-red-50 text-right transition-colors w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="font-semibold mx-auto">Chat</div>
          </div>
          <div
            id="messages-view"
            className="bg-white sm:w-96 w-screen h-72 overflow-auto border-gray-300"
          >
            {messages.map((m) => (
              <div key={m.timestamp} className="ml-1">
                <span className="text-sm mr-2">
                  {format(new Date(m.timestamp), "p")}
                </span>
                <span className="mr-2 font-semibold">{m.username}</span>
                <span>{m.message}</span>
              </div>
            ))}
          </div>
          {!gameState.spectatorIds.includes(clientId) && (
            <form
              className="w-full flex border-gray-300 border-t"
              onSubmit={handleSubmit((data) => {
                sendChatMessage(
                  clientId,
                  gameState.id,
                  data.message,
                  authState.payload?.username ||
                    gameState.players[clientId].name
                );
                reset();
              })}
            >
              <input
                className="w-full p-1"
                autoComplete="off"
                placeholder="Message"
                {...register("message", { required: true })}
              />
              <button
                className="py-1 px-2 bg-blue-500 font-semibold text-blue-100"
                type="submit"
                disabled={chatDisabled}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                </svg>
              </button>
            </form>
          )}
        </div>
      )}
    </>
  );
};

export default Chatroom;
