
import React, { useState } from "react";
import { AuthState, GameState } from "types";

interface Props {
  gameState: GameState | null;
  authState: AuthState | null;
  error: string | null
}

const Debug: React.FC<Props> = ({ gameState, authState, error }) => {
  const [debug, setDebug] = useState(false);
  return (
    <>
      {process.env.NODE_ENV === "development" && (
        <button
          className="bg-gray-700 p-1 rounded text-gray-100 text-xs"
          onClick={() => setDebug(!debug)}
        >
          Debug
        </button>
      )}
      {debug && (
        <>
          <pre className="border p-1 mt-2 text-xs">
            {JSON.stringify(gameState, null, 2)}
          </pre>
          <pre className="border p-1 mt-2 text-xs">
            {JSON.stringify(authState, null, 2)}
          </pre>
          <pre className="border p-1 mt-2 text-xs">
            {JSON.stringify(error, null, 2)}
          </pre>
        </>
      )}
    </>
  );
};

export default Debug;
