import { handleAbandonGame, handleJoinGame } from "api";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { GameState } from "types";

interface Props {
  clientId: string;
  currentGameId: string | null;
  gameState: GameState | null;
  name: string;
}

const RejoinGame: React.FC<Props> = ({ clientId, currentGameId, gameState, name }) => {
  const history = useHistory();
  const [showAbandonModal, setShowAbandonModal] = useState(false);

  if (currentGameId === null) return null;

  return (
    <>
      {showAbandonModal && (
        <div className="fixed w-full left-0 top-0 z-20">
          <div className="flex space-y-2 justify-center items-center h-screen p-2">
            <div className="bg-gray-50 p-2 rounded-lg shadow-2xl">
              <p>
                Are you sure you want to abandon the game? {gameState?.host === "matchmaker" &&`You will lose a
                significant portion of your rating.`}
              </p>
              <div className="flex gap-2">
                <button
                  className="px-2 py-1 bg-red-400 hover:bg-red-300 rounded border border-red-400 text-red-800 font-bold w-full"
                  onClick={() => {
                    handleAbandonGame(clientId, currentGameId);
                  }}
                >
                  Abandon
                </button>
                <button
                  className="px-2 py-1 bg-gray-400 hover:bg-gray-300 rounded border border-gray-400 text-gray-800 font-bold w-full"
                  onClick={() => {
                    setShowAbandonModal(false);
                  }}
                >
                  Nope!
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex gap-2 w-full">
        <button
          className="px-2 py-1 bg-green-400 hover:bg-green-300 rounded border border-green-400 text-green-800 font-bold w-full"
          onClick={() => {
            handleJoinGame(clientId, currentGameId, name);
            history.push(`/game/${currentGameId}`);
          }}
        >
          Rejoin
        </button>
        <button
          className="px-2 py-1 bg-red-400 hover:bg-red-300 rounded border border-red-400 text-red-800 font-bold w-full"
          onClick={() => {
            setShowAbandonModal(true);
          }}
        >
          Abandon
        </button>
      </div>
    </>
  );
};

export default RejoinGame;
