import { cardInfo } from "App";
import Card from "components/Card";
import CardBack from "components/CardBack";
import { ascRank, ascSuit } from "helper/functions";
import React, { useState } from "react";
import { GameState, Player } from "types";
import Plaque from "./Plaque";

interface Props {
  players: { [clientId: string]: Player };
  host: string;
  clientId: string;
  gameState: GameState;
  users: any;
  spectator?: boolean;
}

const cardSpacing = (n: number) =>
  n > 5 ? (0.5 - (5.25 * (n - 5)) / n) / 2 : 0.25;
const South: React.FC<Props> = ({
  players,
  clientId,
  gameState,
  users,
  spectator,
}) => {
  const [showCards, setShowCards] = useState(false);
  const n = players[clientId].cards.length;
  return (
    <div className="px-2">
      <Plaque
        clientId={clientId}
        gameState={gameState}
        users={users}
        spectator={!!spectator}
        showCards={showCards}
        setShowCards={setShowCards}
        textCenter
      />
      <div
        className={`flex justify-center px-2 border-x -mb-12 md:mb-2 rounded-xl ${
          clientId === gameState.currentPlayer
            ? "bg-green-400"
            : gameState.players[clientId].pass
            ? "bg-gray-400"
            : ""
        }`}
      >
        {players[clientId].cards.length !== 0 ? (
          players[clientId].cards
            .sort(ascSuit)
            .sort(ascRank)
            .map((card, i) => (
              <div
                style={{
                  marginRight: `${
                    (i === n - 1 ? 0 : cardSpacing(n)) + 0.25
                  }rem`,
                  marginLeft: `${(i === 0 ? 0 : cardSpacing(n)) + 0.25}rem`,
                }}
                className={`transform `}
                key={`${cardInfo[card].rank}${cardInfo[card].suit}`}
              >
                {spectator && showCards ? (
                  <Card
                    small
                    dragging={false}
                    rank={cardInfo[card].rank}
                    suit={cardInfo[card].suit}
                  />
                ) : (
                  <CardBack small />
                )}
              </div>
            ))
        ) : (
          <div
            className="flex justify-center items-center p-2 bg-gray-200"
            style={{ height: "5.6rem" }}
          >
            <div>No cards remaining</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default South;
