import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { AuthState } from "types";

type Props = {
  authState: AuthState;
  refreshAuthState: () => void;
  products: any;
  prices: any;
  userProducts: any;
};

const Checkout: React.FC<Props> = ({
  prices,
  userProducts,
  authState,
  refreshAuthState,
}) => {
  async function removeFromCart(priceId: string) {
    await fetch(`${process.env.REACT_APP_API_URL}/user/cart/${priceId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      body: JSON.stringify({ username: authState.payload?.username }),
    }).then(() => refreshAuthState());
  }

  useEffect(() => {
    refreshAuthState();
  }, []);

  return (
    <div>
      {authState.payload?.cart.map((priceId: string) => (
        <div key={priceId} onClick={() => removeFromCart(priceId)}>
          {userProducts?.find(
            (product: any) =>
              product?.id ===
              prices?.data.find((_price: any) => _price.id === priceId)?.product
          )?.name || "Invalid Product, click to remove"}
        </div>
      ))}
      {authState.payload?.cart.reduce((prev: number, curr: string) => {
        return (
          prev +
          (prices?.data.find((p: any) => p.id === curr)?.unit_amount || 0)
        );
      }, 0)}
    </div>
  );
};

export default Checkout;
