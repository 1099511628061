import React, { useEffect, useRef, useState } from 'react';

interface Props {
  gameId: string;
}

const JoinLink: React.FC<Props> = ({ gameId }) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [copied, setCopied] = useState(false);
  const [url, setUrl] = useState(window.location.origin);

  useEffect(() => {
    if (process.env.NODE_ENV === "production")
      setUrl("https://daidi.io")
  },[])
  function copyToClipboard() {
    textAreaRef?.current?.select();
    document.execCommand("copy");
    setCopied(true);
  }

  return (
    <div className="flex justify-center">
      <div className="flex text-sm border rounded mt-2">
        <div className="px-2 py-1 border-r bg-gray-200 truncate">Join link</div>
        <textarea
          onClick={copyToClipboard}
          ref={textAreaRef}
          rows={1}
          style={{ resize: "none", overflow: "hidden" }}
          className="px-2 py-1 bg-white focus:outline-none w-56 flex-grow text-center"
          value={`${url}/game/${gameId}`}
          readOnly
        />
        <button className="px-2" onClick={copyToClipboard}>
          {copied ? (
            <svg
              className="h-6 w-6 text-gray-70"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
              />
            </svg>
          ) : (
            <svg
              className="h-6 w-6 text-gray-70"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
              />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
}

export default JoinLink