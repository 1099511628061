import React from "react";

const Footer: React.FC = () => {
  return (
    <div className="my-2 text-gray-600 text-center text-sm">
      <p>
        Like my work? Support me on{" "}
        <a
          className="underline"
          href="https://www.patreon.com/edzh"
          target="_blank"
          rel="noopener noreferrer"
        >
          Patreon
        </a>{" "}
        or by{" "}
        <a
          className="underline"
          href="https://www.buymeacoffee.com/edzh"
          target="_blank"
          rel="noopener noreferrer"
        >
          buying me a coffee
        </a>
        ! You can also leave feedback on{" "}
        <a
          className="underline"
          href="https://www.reddit.com/r/daidi/"
          target="_blank"
          rel="noopener noreferrer"
        >Reddit</a>! We're also on <a
        className="underline"
        href="https://discord.gg/WwfrMKmtsV"
        target="_blank"
        rel="noopener noreferrer"
      >Discord</a>!
      </p>
    </div>
  );
};

export default Footer;
