
import { ws } from "config";
import { AuthState, Options } from "types";

export function handleCreateGame(
  clientId: string,
  gameName: string,
  name: string,
  options: Options,
  matchmake: boolean,
  username?: string,
) {
  const result = {
    type: matchmake ? "matchmaker" : "create",
    payload: {
      clientId: clientId,
      gameName: gameName,
      name: name,
      options: options,
      username: username,
    },
  };

  ws.send(JSON.stringify(result));
}

export function handleJoinGame(clientId: string, gameId: string, name: string, username?: string) {
  const result = {
    type: "join",
    payload: {
      clientId: clientId,
      gameId: gameId,
      name: name,
      username: username
    },
  };

  ws.send(JSON.stringify(result));
}

export function handleSpectateGame(
  clientId: string,
  gameId: string,
) {
  const result = {
    type: "spectate",
    payload: {
      clientId: clientId,
      gameId: gameId,
      name: clientId,
    },
  };

  ws.send(JSON.stringify(result));
}

export function handleStartGame(clientId: string, gameId: string) {
  const result = {
    type: "start",
    payload: {
      clientId: clientId,
      gameId: gameId,
    },
  };

  ws.send(JSON.stringify(result));
}

export function handleReturnToLobby(clientId: string, gameId: string) {
  const result = {
    type: "lobby",
    payload: {
      clientId: clientId,
      gameId: gameId,
    },
  };

  ws.send(JSON.stringify(result));
}

export function handleLeaveGame(clientId: string, gameId: string) {
  const result = {
    type: "leave",
    payload: {
      clientId, gameId
    }
  }

  ws.send(JSON.stringify(result))
}
export function handleAbandonGame(clientId: string, gameId: string) {
  const result = {
    type: "abandon",
    payload: {
      clientId,
      gameId,
    },
  };

  ws.send(JSON.stringify(result));
}
export function handlePlayGame(clientId: string, gameId: string, cards: string[]) {
  const result = {
    type: "play",
    payload: {
      clientId: clientId,
      gameId: gameId,
      cards: cards,
    },
  };

  ws.send(JSON.stringify(result));
}

export function handleUpdateRules(clientId: string, gameId: string, options: Options) {
  const result = {
    type: "options",
    payload: {
      clientId: clientId,
      gameId: gameId,
      options: options
    }
  }

  ws.send(JSON.stringify(result))
}

export async function emptyCart(authState: AuthState) {
  await fetch(`${process.env.REACT_APP_API_URL}/user/empty-cart`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    },
    body: JSON.stringify({ username: authState.payload?.username }),
  });
}

export async function updateUserRules(authState: AuthState, userProducts: any) {
  const gameRules = userProducts.map((product:any) => product.metadata)
  console.log("gameRules", gameRules)

  await fetch(`${process.env.REACT_APP_API_URL}/user/rules`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    },
    body: JSON.stringify({
      username: authState.payload?.username,
      gameRules: gameRules,
    }),
  });
}
