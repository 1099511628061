import { isAuthenticated } from "helper/functions";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import { AuthState } from "types";

const SignUp = ({
  authState,
  setAuthState,
}: {
  authState: AuthState;
  setAuthState: React.Dispatch<React.SetStateAction<AuthState>>;
}) => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { isValid },
    getValues,
  } = useForm();

  //redirect to homepage if already authenticated
  useEffect(() => {
    if (authState.isAuthenticated) {
      history.push("/");
    }
  }, [authState.isAuthenticated]);

  const onSubmit = async (data: any) => {
    console.log(data);
    const token = await fetch(`${process.env.REACT_APP_API_URL}/auth/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: data.username,
        password: data.password,
        email: data.email
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        localStorage.setItem("jwt", json.token);
        return json.token;
      })
      .catch((e) => console.error(e));

    const getAuthState = async () => {
      const authState = await isAuthenticated(token);
      setAuthState(authState);
    };
    getAuthState();
  };

  return (
    <div className="max-w-5xl mx-auto px-2">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Register a new account
        </h2>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div>
          <label
            htmlFor="username"
            className="block text-sm font-medium text-gray-700"
          >
            Username
          </label>
          <div className="mt-1">
            <input
              {...register("username", { required: true })}
              id="username"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <div className="mt-1">
            <input
              {...register("email", {
                required: true,
                pattern: {
                  value: /\S+@\S+.\S+/,
                  message: "Entered value does not match email format",
                },
              })}
              id="email"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            Password (8 characters min)
          </label>
          <div className="mt-1">
            <input
              {...register("password", {
                required: true,
                minLength: {
                  value: 8,
                  message: "min length is 8",
                },
              })}
              id="password"
              type="password"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="confirmPassword"
            className="block text-sm font-medium text-gray-700"
          >
            Confirm Password
          </label>
          <div className="mt-1">
            <input
              {...register("confirmPassword", {
                required: true,
                validate: (value) => value === getValues("password"),
              })}
              id="confirmPassword"
              type="password"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            className={`bg-indigo-600 hover:bg-indigo-700 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          >
            Sign Up
          </button>
        </div>
      </form>
    </div>
  );
};

export default SignUp;
